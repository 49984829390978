import React, {useEffect} from 'react';
import styles from "./styles.module.scss";
import Header from "../../component/Header/header";
import {useParams} from "react-router-dom";
import {useMutation} from "react-query";
import {registerSignatureAccount} from "../../services/services";
import handleErrorMessage from "../../helper/handleErrorMessage";
import {toast} from "react-toastify";
import Preloader from "../../component/Preloader";

const SignatureAccount = () => {

  const params = useParams();

  const {mutate, isLoading} = useMutation((hash: string) =>
    registerSignatureAccount(hash), {
    onSuccess: (response) => {
      if (response.data) {
        if (!response.data.error) {
          toast.success(response.data.msg);
          setTimeout(() => {
            window.location.href = process.env.REACT_APP_AREA_CLIENTE_URL ? process.env.REACT_APP_AREA_CLIENTE_URL + "/?from=checkout&redirect=#/login" : '/';
          }, 1000);
        } else {
          toast.error(response.data.msg);
        }
      } else {
        toast.error("Erro inesperado, tente mais tarde");
      }
    },
    onError: (error: { message: string, response: { status: number, data: { errors: [] } } }) => {
      handleErrorMessage(error.response);
    }
  });

  useEffect(() => {
    if (params.hash)
      mutate(params.hash)
  }, [])

  return (<>
      <Preloader isVisible={true}/>
      <div className={styles.separateDivs}>
        <Header></Header>
        <div className={styles.content}>
        </div>
      </div>
    </>
  )
}

export default SignatureAccount
