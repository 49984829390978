import api from './api';
import { ShippingService } from '../ts/interfaces/Shipping';
import { UserSubmitForm } from '../views/Register/Register';
import { UserLoginForm } from '../views/Login/Login';
import { Customer } from '../ts/interfaces/Customer';
import { Address } from '../ts/interfaces/Address';

export const register = (data: UserSubmitForm) => Promise.resolve(api.post('/api/v1/auth/register', data));

export const login = (data: UserLoginForm) => Promise.resolve(api.post('/api/v1/auth/login', data));

export const rememberPassword = (data: { email: string, token: string|null }) => Promise.resolve(api.post(`/api/v1/auth/remember-password`, data));

export const changePassword = (data: {password: string, password_confirmation: string, code: string, token: string}) => Promise.resolve(api.put(`/api/v1/auth/user/password`, data));

export const cartAddItem = (cartHash: string, coupon: string|null) => Promise.resolve(api.post(`/api/v1/cart/item?t=${cartHash}`, {coupon: coupon}));

export const getCart = () => Promise.resolve(api.get('/api/v1/cart'));

export const calculateShipping = (zipCode: string) => Promise.resolve(api.get(`/api/v1/cart/cep_calculate/${zipCode}`));

export const selectShippingService = (shippingService: ShippingService) => Promise.resolve(api.post('/api/v1/cart/shipping', shippingService));

export const incrementProduct = (sku: string) => Promise.resolve(api.put(`/api/v1/cart/item/increment/${sku}`));

export const decrementProduct = (sku: string) => Promise.resolve(api.put(`/api/v1/cart/item/decrement/${sku}`));

export const removeProduct = (sku: string) => Promise.resolve(api.delete(`/api/v1/cart/item/${sku}`));

export const getCustomer = () => Promise.resolve(api.get('/api/v1/cart/customer'));

export const getAddressToSendProduct = () => Promise.resolve(api.get('/api/v1/cart/customer/address/default'));

export const getMyAddresses = () => Promise.resolve(api.get('/api/v1/cart/customer/address'));

export const selectAddress = (addressId: number) => Promise.resolve(api.get(`/api/v1/cart/customer/address/select/${addressId}`));

export const registerAddress = (address: Address) => Promise.resolve(api.post('/api/v1/cart/customer/address', { ...address }));

export const deleteAddress = (addressId: number) => Promise.resolve(api.delete(`/api/v1/cart/customer/address/${addressId}`));

export const saveCustomer = (data: Customer) => Promise.resolve(api.post('/api/v1/cart/customer', data));

export const createPaymentMode = (method: any) => Promise.resolve(api.post('api/v1/cart/payment', method));

export const getOrderByCart = () => Promise.resolve(api.get(`/api/v1/cart/order`));

export const getOrder = (orderId: string) => Promise.resolve(api.get(`/api/v1/cart/order/${orderId}`));

export const applyCouponCart = (data: {coupon: string}) => Promise.resolve(api.post(`/api/v1/cart/coupon`, data));

export const selectPaymentMethodCart = (data: {payment_method: string}) => Promise.resolve(api.post(`/api/v1/cart/payment-method`, data));

export const registerSignatureAccount = (hash: string) => Promise.resolve(api.post(`/api/v1/customerarea/signature-account/register/${hash}`));

export const recoverCart = (data: { session_id: string }) => Promise.resolve(api.post(`/api/v1/cart/recover/`, data));

export const selectOrderBump = (data: {ids: number[]}) => Promise.resolve(api.post(`/api/v1/cart/order-bump`, data));

export const resetCheckoutDiscount = () => Promise.resolve(api.put('/api/v1/cart/reset-checkout-discount'));

export const applyCashbackCart = (data: {apply: boolean}) => Promise.resolve(api.post(`/api/v1/cart/cashback`, data));
export const getStatusOrder = (orderId: string) => Promise.resolve(api.get(`/api/v1/cart/order-status/${orderId}`));

