import React, { useEffect, useRef, useState } from 'react';

import styles from "../styles.module.scss";
import MaskedInput from "../../../component/MaskedInput";

import {formatMoneyMask} from "../../../helper/FormatFields";

import credit from "../../../assets/icons/creditCard.svg";
import arrowRight from "../../../assets/icons/arrowRight.svg";

import getInstallmentValue from "../../../helper/InstallmentHelper";

import {Cart} from "../../../ts/interfaces/Cart";

interface PagarmeCCProps {
  option: string;
  selectPaymentMethod: Function,
  formRef: any,
  handleSubmit: any,
  onSubmit: any,
  register: any,
  errors: any,
  installments: number[],
  cart: Cart
}

export default function PagarmeCC({ option, selectPaymentMethod, formRef, handleSubmit, onSubmit, register, errors, installments, cart }: PagarmeCCProps) {

  function renderCardOption() {
    return (
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.result}>
          <p>Número do cartão</p>
          <MaskedInput
            mask={"9999.9999.9999.9999"}
            fieldName='card_number'
            errorMessage={errors.card_number?.message}
            register={register}
          />
          <p>Nome impresso no cartão</p>
          <input
            {...register('card_holder_name')}
          />
          {errors.card_holder_name && (
            <span className={styles.error}>
              {errors.card_holder_name?.message}
            </span>
          )}
          <div className={styles.align}>
            <div>
              <p>Validade</p>
              <MaskedInput
                mask={"99/9999"}
                className={styles.validate}
                fieldName='card_expiration_date'
                register={register}
                errorMessage={errors.card_expiration_date?.message}
              />
            </div>
            <div>
              <p>Código segurança</p>
              <input
                maxLength={4}
                className={styles.code}
                {...register('card_cvv')}
              />
              {errors.card_cvv && (
                <span className={styles.error}>
                  {errors.card_cvv?.message}
                </span>
              )}
            </div>
          </div>
          <p>Parcelas</p>
          <select className={styles.installments} {...register('installments')}>
            {installments.length === 0 ? <option key="0" value="1">Calculando...</option> : ""}
            {installments.map((item, index) =>
              <option key={index + 1} value={index + 1}>{index + 1}x
                de {formatMoneyMask(item.toString())}</option>
            )}
          </select>
          {cart.is_recurring ? <p className={styles.infoRecurring}>Se o seu cartão estiver sem limite, iremos cobrar de forma recorrente o valor total do pedido.</p> : ""}
        </div>
      </form>
    )
  }

  return <>
    <div
      onClick={() => selectPaymentMethod('pagarme_cc')}
      className={option === 'pagarme_cc' ? styles.option + " " + styles.active : styles.option}
    >
      <div className={styles.group}>
        <img src={credit} alt="credit"/>
        <p>Cartão de crédito<span>Até {getInstallmentValue(cart.total_value)} vezes sem juros</span></p>
      </div>
      <div>
        <img src={arrowRight} alt="arrow"/>
      </div>
    </div>
    {option === 'pagarme_cc' && (
      renderCardOption()
    )}
  </>
}
