import React, {FC, useState} from 'react';
import {useGoogleLogin} from '@react-oauth/google';

import styles from "./styles.module.scss";
import {ReactComponent as GoogleIcon} from './google.svg';

import axios from "axios";

interface LoginGoogleProps {
    onClickBefore: Function
    callback: Function
}


const LoginGoogle: FC<LoginGoogleProps> = ({onClickBefore, callback}) => {

  const [loading, setLoading] = useState(false);

    const login = useGoogleLogin({
        onSuccess: async (response) => {
            let userApi = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo?access_token=' + response.access_token);
            callback(userApi.data.name, userApi.data.email, response.access_token, userApi.data.sub);
            setLoading(false);
        },
        onError: async (error) => {
          setLoading(false);
          console.error(error);
        },
    });

    return (
        <>
            <button disabled={loading} className={styles.buttonGoogle} onClick={() => {
                if(onClickBefore()) {
                    setLoading(true);
                    setTimeout(() => {setLoading(false);}, 5000)
                    login()
                }
            }}><GoogleIcon/></button>
        </>
    )
}

export default LoginGoogle
