import styles from "./styles.module.scss";
import React, {useEffect} from 'react';
import {Order} from "../../ts/interfaces/Order";

interface PaymentResumeProps {
  order: Order;
}

const Ebit = ({order}: PaymentResumeProps) => {

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "getSelo";
    script.src = `https://imgs.ebit.com.br/ebitBR/selo-ebit/js/getSelo.js?${process.env.REACT_APP_EBIT_CODE}&lightbox=true`;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const productNames = order.items.map((item: any) => item.name);
  const productNameParam = productNames.join('|');
  const skus = order.items.map((item: any) => encodeURIComponent(item.sku));
  const skuParam = skus.join('|');
  const totalQuantity = order.items.map((item: any) => item.qty);
  const totalQuantityParam = totalQuantity.join('|');
  const price = order.items.map((item: any) => item.price);
  const formatter = new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});
  const priceParam = price.map((p: any) => formatter.format(parseFloat(p))).join('|');
  const storeId = Number(process.env.REACT_APP_EBIT_CODE);
  const storeName = process.env.REACT_APP_EBIT_NAME;
  const paymentTypeCode = order.payment.method === "pagarme_cc"
    ? "05"
    : order.payment.method === "pagarme_pix"
      ? "90"
      : order.payment.method === "pagarme_boleto"
        ? "08"
          : "28";

  return (
    <>
      <div className={styles.content}>
        <a
          id="bannerEbit"
          href={`http://www.ebit.com.br/${process.env.REACT_APP_EBIT_CODE}`}
          target="_blank"
          rel="noopener noreferrer"
          data-noop="redir(this.href);"
        >
          <img alt={"ebit"} src="https://www.ebitempresa.com.br/bitrate/banners/b1320635.gif"/>
        </a>
        <param
          id="ebitParam"
          value={`
            storeId=${storeId}
            &transactionId=${order.increment_id}
            &platform=${window.innerWidth > 768 ? '0' : '1'}
            &gender=gender
            &zipCode=${order.zipcode.replace("-", "")}
            &parcels=${order.payment.installments}
            &deliveryTax=${order.shipping_price ? Number(order.shipping_price).toFixed(2) : "0"}
            &deliveryTime=${order.shipping_days ? order.shipping_days : "0"}
            &totalSpent=${Number(order.grand_total).toFixed(2)}
            &value=${priceParam}
            &quantity=${totalQuantityParam}
            &productName=${productNameParam}
            &paymentType=${paymentTypeCode}
            &sku=${skuParam}
            &productCondition=0
            &deliveryType=1
            &mktSaleId=0
            &invoiceEmissor=19
            &man=${storeName}
            &brd=${storeName}
            &brds=" "`}
        />
      </div>
    </>
  );
};

export default Ebit;
