import React, {useEffect, useState} from 'react'
import styles from "./styles.module.scss";

import card from "../../../../assets/icons/iconCard.svg";
import cardBlue from "../../../../assets/icons/iconCardBlue.svg";
import arrow from "../../../../assets/icons/arrowCupom.svg";

export default function TwoCard() {

  const [width, setWidth] = useState(window.innerWidth);
  const [option, setOption] = useState(1);

  function isDesktop() {
    return width > 768
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);


  return (
    <>
      {isDesktop() ?
        <div className={styles.form}>
          <div className={styles.align}>
            <p>Cartão 1</p>
            <div className={styles.information}>
              <p>Número do cartão</p>
              <input
                id="name"
                type="text"
              />
            </div>
            <div className={styles.information}>
              <p>Nome impresso no cartão</p>
              <input
                id="cpf"
                maxLength={14}
              />
            </div>
            <div className={styles.group}>
              <div className={styles.information}>
                <p>Validade</p>
                <input
                  id="email"
                  type="text"
                />
              </div>
              <div className={styles.information}>
                <p>Código segurança</p>
                <input
                  id="email"
                  type="text"
                />
              </div>
            </div>
            <div className={styles.information}>
              <p>CPF do titular do cartão</p>
              <input
                id="cpf"
                maxLength={14}
              />
            </div>
            <div className={styles.information}>
              <p>Parcelas</p>
              <select>
                <option>12x de R$250</option>
              </select>
            </div>
          </div>

          <div className={styles.align}>
            <p>Cartão 2</p>
            <div className={styles.information}>
              <p>Número do cartão</p>
              <input
                id="name"
                type="text"
              />
            </div>
            <div className={styles.information}>
              <p>Nome impresso no cartão</p>
              <input
                id="cpf"
                maxLength={14}
              />
            </div>
            <div className={styles.group}>
              <div className={styles.information}>
                <p>Validade</p>
                <input
                  id="email"
                  type="text"
                />
              </div>
              <div className={styles.information}>
                <p>Código segurança</p>
                <input
                  id="email"
                  type="text"
                />
              </div>
            </div>
            <div className={styles.information}>
              <p>CPF do titular do cartão</p>
              <input
                id="cpf"
                maxLength={14}
              />
            </div>
            <div className={styles.information}>
              <p>Parcelas</p>
              <select>
                <option>12x de R$250</option>
              </select>
            </div>
          </div>
        </div>
        :
        <div className={styles.options}>
          <div onClick={() => setOption(1)} className={option == 1 ? styles.active : styles.card}>
            <div className={styles.header}>
              <div className={styles.source}>
                <img src={option == 1 ? cardBlue : card} alt={"card"}/>
                <p>Cartão 1</p>
              </div>
              <img className={styles.arrow} src={arrow} alt={"arrow"}/>
            </div>
            {option == 1 ?
              <div className={styles.align}>
                <div className={styles.information}>
                  <p>Número do cartão</p>
                  <input
                    id="name"
                    type="text"
                  />
                </div>
                <div className={styles.information}>
                  <p>Nome impresso no cartão</p>
                  <input
                    id="cpf"
                    maxLength={14}
                  />
                </div>
                <div className={styles.onlyMobile}>
                  <div className={styles.information}>
                    <p>Validade</p>
                    <input
                      className={styles.inputMobile}
                      id="email"
                      type="text"
                    />
                  </div>
                  <div className={styles.information}>
                    <p>Código segurança</p>
                    <input
                      className={styles.inputMobile}
                      id="email"
                      type="text"
                    />
                  </div>
                </div>
                <div className={styles.information}>
                  <p>CPF do titular do cartão</p>
                  <input
                    id="cpf"
                    maxLength={14}
                  />
                </div>
                <div className={styles.information}>
                  <p>Parcelas</p>
                  <select>
                    <option>12x de R$250</option>
                  </select>
                </div>
              </div> : ""}
          </div>
          <div onClick={() => setOption(2)} className={option == 2 ? styles.active : styles.card}>
            <div className={styles.header}>
              <div className={styles.source}>
                <img src={option == 2 ? cardBlue : card} alt={"card"}/>
                <p>Cartão 2</p>
              </div>
              <img className={styles.arrow} src={arrow} alt={"arrow"}/>
            </div>
            {option == 2 ?
              <div className={styles.align}>
                <div className={styles.information}>
                  <p>Número do cartão</p>
                  <input
                    id="name"
                    type="text"
                  />
                </div>
                <div className={styles.information}>
                  <p>Nome impresso no cartão</p>
                  <input
                    id="cpf"
                    maxLength={14}
                  />
                </div>
                <div className={styles.onlyMobile}>
                  <div className={styles.information}>
                    <p>Validade</p>
                    <input
                      className={styles.inputMobile}
                      id="email"
                      type="text"
                    />
                  </div>
                  <div className={styles.information}>
                    <p>Código segurança</p>
                    <input
                      className={styles.inputMobile}
                      id="email"
                      type="text"
                    />
                  </div>
                </div>
                <div className={styles.information}>
                  <p>CPF do titular do cartão</p>
                  <input
                    id="cpf"
                    maxLength={14}
                  />
                </div>
                <div className={styles.information}>
                  <p>Parcelas</p>
                  <select>
                    <option>12x de R$250</option>
                  </select>
                </div>
              </div> : ""}
          </div>
        </div>
      }
    </>
  )
}
