import React, {useEffect, useState} from 'react';
import styles from "./styles.module.scss";

import HeaderComponent from "../Header";
import CreditCard from "../PaymentMethods/CreditCard/CreditCard";

import pointer from "../../../assets/icons/pointerArrow.svg";
import arrow from "../../../assets/icons/arrow.svg";
import blueCredit from "../../../assets/icons/blueCard.svg";
import clayCredit from "../../../assets/icons/clayCreditCard.svg";
import clayPix from "../../../assets/icons/clayPix.svg";
import bluePix from "../../../assets/icons/bluePix.svg";
import clayUpc from "../../../assets/icons/clayUpc.svg";
import blueUpc from "../../../assets/icons/blueUpc.svg";
import cupom from "../../../assets/icons/arrowCupom.svg";
import lock from "../../../assets/icons/iconLock.svg";
import TwoCard from "../PaymentMethods/TwoCard/TwoCard";
import Ticket from "../PaymentMethods/Ticket/Ticket";
import Pix from "../PaymentMethods/Pix/Pix";
import {Swiper, SwiperSlide} from "swiper/react";

import "swiper/css";


export default function PaymentPay() {

  const [option, setOption] = useState(1);
  const [click, setClick] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  function isDesktop() {
    return width > 768
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (
    <>
      <HeaderComponent/>
      <div className='main-container'>
        <div className={styles.offer}>
          <div className={styles.ad}>
            <p className={styles.top}>Oferta Especial</p>
            <div className={styles.mid}>
              <div className={styles.check}>
                <input className={styles.checkbox} type={"checkbox"}/>
                <p>600 Questões Gabaritadas Polícia Penal-MG em PDF</p>
              </div>
              <div className={styles.midContent}>
                <div className={styles.group}>
                  <span>de R$ 67,90</span>
                  <p>por R$ 59,90</p>
                </div>
                <p className={styles.percentage}>(30% de desconto)</p>
              </div>
            </div>
            <div className={styles.count}>
              <p>Expira em 00:00</p>
            </div>
          </div>
        </div>


        <div className={styles.box}>
          <div className={styles.data}>
            <div className={styles.header}>
              <div className={styles.firstPage}>
                <p className={styles.number}>1</p>
                <span>Dados pessoais</span>
              </div>
              <img className={styles.partition} src={pointer} alt={"pointer"}/>
              <div className={styles.secondPage}>
                <p className={styles.number}>2</p>
                <span>Pagamento</span>
              </div>
            </div>
            <div className={styles.body}>
              <div className={styles.options}>
                {isDesktop() ?
                  <>
                    <button onClick={() => setOption(1)}
                            className={option === 1 ? styles.active : styles.option}><img
                      src={option === 1 ? blueCredit : clayCredit} alt={"credit-card"}/> Cartão de crédito
                    </button>
                    <button onClick={() => setOption(2)}
                            className={option === 2 ? styles.active : styles.option}><img
                      src={option === 2 ? blueCredit : clayCredit}/>Dois cartões
                    </button>
                    <button onClick={() => setOption(3)}
                            className={option === 3 ? styles.active : styles.option}><img
                      src={option === 3 ? bluePix : clayPix}/>Pix
                    </button>
                    <button onClick={() => setOption(4)}
                            className={option === 4 ? styles.active : styles.option}><img
                      src={option === 4 ? blueUpc : clayUpc}/>Boleto
                    </button>
                  </>
                  :
                  <Swiper slidesPerView={window.innerWidth < 480 ? 2 : 3} spaceBetween={-8}>
                    <SwiperSlide>
                      <button onClick={() => setOption(1)}
                              className={option === 1 ? styles.active : styles.option}><img
                        src={option === 1 ? blueCredit : clayCredit} alt={"credit-card"}/> Cartão de crédito
                      </button>
                    </SwiperSlide>
                    <SwiperSlide>
                      <button onClick={() => setOption(2)}
                              className={option === 2 ? styles.active : styles.option}><img
                        src={option === 2 ? blueCredit : clayCredit}/>Dois cartões
                      </button>
                    </SwiperSlide>
                    <SwiperSlide>
                      <button onClick={() => setOption(3)}
                              className={option === 3 ? styles.active : styles.option}><img
                        src={option === 3 ? bluePix : clayPix}/>Pix
                      </button>
                    </SwiperSlide>
                    <SwiperSlide>
                      <button onClick={() => setOption(4)}
                              className={option === 4 ? styles.active : styles.option}><img
                        src={option === 4 ? blueUpc : clayUpc}/>Boleto
                      </button>
                    </SwiperSlide>
                  </Swiper>}
              </div>

              {option == 1 && <CreditCard/>}
              {option == 2 && <TwoCard/>}
              {option == 3 && <Pix/>}
              {option == 4 && <Ticket/>}

              <div className={option == 4 ? styles.burgh : styles.formGroup}>
                {option == 4 && <span>Aprovado em até 2 dias após o pagamento</span>}
                <button type="submit" className={styles.btnBuy}>
                  Finalizar compra
                  <img src={arrow} alt="arrow"/>
                </button>
              </div>
            </div>
          </div>
          <div className={styles.summary}>
            <div className={styles.boxSummary}>
              <div className={styles.title}>
                <p>Resumo da compra</p>
                <div className={styles.safety}>
                  <p><img src={lock} alt={"lock"}/>COMPRA 100% SEGURA</p>
                </div>
              </div>
              <table>
                <tr>
                  <td className={styles.info}>Apostila Polícia Penal MG 2021 - Agente de Segurança Penitenciário (PP-MG)
                  </td>
                  <td>10</td>
                  <td>R$ 75,00</td>
                </tr>
                <tr>
                  <td className={styles.info}>Apostila PC-MG 2021 Escrivão</td>
                  <td>1</td>
                  <td>R$ 99,00</td>
                </tr>
              </table>
              <div className={styles.discount}>
                <hr/>
                <div className={styles.agroupment}>
                  <p className={styles.strong}>Desconto</p>
                  <p>- R$ 15,00</p>
                </div>
              </div>
              <div className={styles.totalPrice}>
                <div className={styles.alignPrice}>
                  <p>Total</p>
                  <p>12 x R$ 10,00</p>
                </div>
                <div className={styles.inCash}>
                  <span>Total à vista</span>
                  <span>R$ 120,00</span>
                </div>
              </div>
            </div>
            <div className={styles.areaCupom}>
              <div onClick={() => setClick(!click)} className={styles.btn}>
                <p>Você possui um cupom?</p>
                <img className={click ? styles.open : styles.close} src={cupom} alt={"cupom"}/>
              </div>
              {click &&
                <div className={styles.insert}>
                  <p>Cupom</p>
                  <div className={styles.btnAplicar}>
                    <input/>
                    <button>
                      Aplicar
                    </button>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
