import { formatMoneyMask } from '../../../helper/FormatFields';
import { ShippingService } from '../../../ts/interfaces/Shipping';
import './styles.scss';

interface ShippingListProps {
  shippingSelected: boolean;
  shipping: ShippingService;
  onSelectShipping: (shipping: ShippingService) => void;
}

function getShippingSelectedStyles(shippingSelected: boolean) {
  if (shippingSelected) {
    return {
      shipping: 'shipping-selected',
      checkbox: 'checkbox-selected'
    }
  } else {
    return {
      shipping: 'shipping-default',
      checkbox: 'checkbox-default'
    }
  }
}

export default function ShippingList({ shippingSelected, shipping, onSelectShipping }: ShippingListProps) {
  const selectedStyles = getShippingSelectedStyles(shippingSelected);

  return (
    <div
      className='shipping-list-container'
      onClick={() => onSelectShipping(shipping)}
    >
      <div className={`shipping ${selectedStyles.shipping}`}>
        <div className={`checkbox ${selectedStyles.checkbox}`}>
          <div />
        </div>

        <div className="shipping-details">
          <p>
            {shipping.discount > 0 ? <>
              <span className={"old-price"}>{formatMoneyMask(shipping.price.toString())}</span>
              <span className={"new-price"}> {formatMoneyMask((shipping.price - shipping.discount).toString())}</span>
            </> : <>
              <span className={"new-price"}>{formatMoneyMask(shipping.price.toString())}</span>
            </>}
          </p>
          <p>
           {shipping.name}: receba em até <span className='text-bold'>{shipping.days} </span>
            dias úteis após a confirmação do pagamento
          </p>
        </div>
      </div>
    </div>
  )
}
