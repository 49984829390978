import { ChangeEvent } from 'react';

import './styles.scss';

interface InputZipCodeProps {
  fieldName: string;
  buttonLabel: string;
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  register?: any;
  className?: string;
  errorMessage?: string;
  onClick: () => void;
  onChangeField?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export default function InputZipCode({
  disabled,
  placeholder,
  errorMessage,
  register,
  fieldName,
  label,
  className,
  buttonLabel,
  onClick,
  onChangeField,
}: InputZipCodeProps) {
  return (
    <div className={`input-zipcode-container ${className && className}`}>
      {label && (
        <span className='label'>Informe o CEP</span>
      )}
      <div className='input-zipcode'>
        <input
          maxLength={9}
          placeholder={placeholder}
          {...register(fieldName, {
            onChange: onChangeField
          })}
        />

        <button
          type='button'
          className='btn-calculate'
          disabled={disabled}
          onClick={onClick}
        >
          <span>{buttonLabel}</span>
        </button>
      </div>
      {errorMessage && (
        <span className='error'>{errorMessage}</span>
      )}
    </div>
  )
}
