import React, {FC, useState} from 'react';
// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import styles from "./styles.module.scss";
import {ReactComponent as FaceIcon} from './facebook.svg';

interface LoginFacebookProps {
    onClickBefore: Function,
    callback: Function
}

const LoginFacebook: FC<LoginFacebookProps> = ({onClickBefore, callback}) => {

  const [loading, setLoading] = useState(false);

  return (
        <FacebookLogin
            redirectUri={window.location.href}
            appId={process.env.REACT_APP_APP_ID_FACEBOOK}
            autoLoad={false}
            fields="name,email"
            scope="public_profile, email"
            callback={(response: any) => {
                console.log(response);
                callback(response.name, response.email, response.accessToken, response.userID);
            }}
            render={(renderProps: any) => (
                <button disabled={loading} className={styles.buttonFacebook} onClick={() => {
                    if(onClickBefore()) {
                        setLoading(true);
                        setTimeout(() => {setLoading(false);}, 5000);
                        renderProps.onClick()
                    }
                }}>
                    <FaceIcon/>
                </button>
            )}
        />
    )
}

export default LoginFacebook
