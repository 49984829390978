import { ChangeEvent, forwardRef } from 'react';
import './styles.scss';

interface InputProps {
  label: string;
  errorMessage?: string;
  placeholder?: string;
  readonly?: boolean;
  register: any;
  fieldName: string;
  maxLength?: number;
  onChangeField?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export default forwardRef((props: InputProps, ref) => {
  const {
    label,
    errorMessage,
    placeholder,
    fieldName,
    register,
    maxLength,
    onChangeField,
    readonly = false,
  } = props;

  return (
    <div className='input-form'>
      <span className='label'>{label}</span>
      <input
        placeholder={placeholder}
        readOnly={readonly}
        maxLength={maxLength}
        {...register(fieldName, {
          onChange: onChangeField
        })}
      />
      {errorMessage && (
        <span className='error'>{errorMessage}</span>
      )}
    </div>
  )
});
