import React, {useRef, useState} from 'react'
import styles from "./styles.module.scss";

import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {createPaymentMode} from "../../../../services/services";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
//@ts-ignore
import pagarme from 'pagarme/browser';
import MaskedInput from "../../../../component/MaskedInput";
import * as Yup from "yup";
interface Card {
  card_number: string,
  card_expiration_date: string,
  card_cvv: string,
  card_holder_name: string,
}

interface Schema extends Card {
  installments: number;
}

export default function CreditCard() {

  const formRef = useRef<any>()
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [option, setOption] = useState(1);

  const validationSchema = Yup.object().shape({
    card_holder_name: Yup.string().required('Preencha o nome'),
    card_number: Yup.string().required('Preencha o número do cartão'),
    card_cvv: Yup.string().required('Preencha o código segurança'),
    card_expiration_date: Yup.string().required('Preencha a validade do cartão')
  });

  async function onSubmit(values: any) {

    setLoading(true);

    const options = {
      method: option === 1 ? 'pagarme_cc' : (option === 2 ? 'pagarme_pix' : 'pagarme_boleto'),
      card_hash: null,
      installments: 1
    }

    if (option === 1) {
      const cardHash = await getCardPaymentInformations(values);
      if (!cardHash) {
        return;
      }
      options.card_hash = cardHash;
      options.installments = Number(values.installments) + 1;
    }

    try {
      const {data} = await createPaymentMode(options);
      if (data.success) {
        navigate('finalizado', {replace: true});
      } else {
        toast.error(data.msg);
      }
    } catch (error) {
      toast.error('Ocorreu um erro inesperado, nossos analistas foram notificados, tente mais tarde.');
      console.log('CARD_PAYMENT:ERRO', error);
    } finally {
      setLoading(false);
    }
  }

  async function getCardPaymentInformations(card: Card) {
    const response = pagarme.client.connect({encryption_key: process.env.REACT_APP_PAGARME_KEY})
      .then((client: any) => client.security.encrypt(card))
      .catch((error: any) => {
        console.error('PAGARME::ERROR', error);
        toast.error('Ocorreu um erro inesperado, nossos analistas foram notificados, tente mais tarde.');
      });

    return response;
  }

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<Schema>({
    resolver: yupResolver(validationSchema),
  });



  return (
    <>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.form}>
          <div className={styles.align}>
            <div className={styles.information}>
              <p>Número do cartão</p>
              <MaskedInput
                mask={"9999.9999.9999.9999"}
                fieldName='card_number'
                errorMessage={errors.card_number?.message}
                register={register}
              />
            </div>
            <div className={styles.information}>
              <p>Nome impresso no cartão</p>
              <input
                {...register('card_holder_name')}
              />
              {errors.card_holder_name && (
                <span className={styles.error}>
              {errors.card_holder_name?.message}
            </span>
              )}
            </div>
            <div className={styles.onlyMobile}>
              <div className={styles.information}>
                <p>Validade</p>
                <input
                  className={styles.inputMobile}
                  id="email"
                  type="text"
                />
              </div>
              <div className={styles.information}>
                <p>Código segurança</p>
                <input
                  className={styles.inputMobile}
                  id="email"
                  type="text"
                />
              </div>
            </div>
            <div className={styles.information}>
              <p>CPF do titular do cartão</p>
              <input
                id="cpf"
                maxLength={14}
              />
            </div>
          </div>
          <div className={styles.information}>
            <p>Parcelas</p>
            <select>
              <option>12x de R$250</option>
            </select>
          </div>
        </div>
      </form>
    </>
  )
}
