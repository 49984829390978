import loadingIcon from '../../assets/icons/loading.svg';
import './styles.scss';

interface LoadingProps {
  size: 'SMALL' | 'MEDIUM' | 'BIG' | 'BIG2X';
}

export default function Loading({ size }: LoadingProps) {
  function getIconSize() {
    if (size === 'BIG') {
      return 'big-icon'
    }

    if (size === 'SMALL') {
      return 'small-icon'
    }

    if (size === 'BIG2X') {
      return 'big2x-icon'
    }

    return 'medium-icon'
  }

  return (
    <img className={getIconSize()} src={loadingIcon} alt="loading" />
  )
}
