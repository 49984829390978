import ReactDom from 'react-dom';
import loading from '../../assets/icons/loading.svg';

import './styles.scss';

interface LoadingProps {
  isVisible: boolean;
}

export default function Loading({ isVisible }: LoadingProps) {
  if (!isVisible) {
    return null;
  }

  return ReactDom.createPortal(
    <div className='container-loader'>
      <img src={loading} alt="loader"/>
    </div>,
    document.getElementById('loader-root') as HTMLElement
  )
}
