import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import arrowIcon from '../../assets/icons/arrowRight.svg';
import { formatPostalCode, formatMoneyMask } from '../../helper/FormatFields';
import { ShippingService } from '../../ts/interfaces/Shipping';

import ShippingServicesList from '../../component/ShippingServicesList'
import Loading from '../../component/Loading';

import './styles.scss';
import {Cart} from "../../ts/interfaces/Cart";
import getInstallmentValue from "../../helper/InstallmentHelper";
import styles from "../../views/Payment/styles.module.scss";

interface CartResumeProps {
  isOnlyDigitalProduct: boolean;
  productsQuantity: number;
  shippingServicesList: ShippingService[];
  postalCodeSaved: string;
  isLoadingResume: boolean;
  onSelectShippingService: (item: ShippingService) => void;
  onChangePostalCode: (postalCode: string) => void;
  onCalculateShipping: () => void;
  cart: Cart
}

export default function CartResume({
  isOnlyDigitalProduct,
  productsQuantity,
  shippingServicesList,
  postalCodeSaved,
  isLoadingResume,
  onSelectShippingService,
  onChangePostalCode,
  onCalculateShipping,
  cart
}: CartResumeProps) {
  const navigate = useNavigate();
  const [postalCode, setPostalCode] = useState(formatPostalCode(postalCodeSaved) || '');

  function handlePostalCode(e: any) {
    if(e.target.value.length < 10) {
      const formatedPostalCode = formatPostalCode(e.target.value);
      setPostalCode(formatedPostalCode!);
      onChangePostalCode(formatedPostalCode!);
    }
  }

  function showCartItemsQuantity() {
    if (productsQuantity > 1) {
      return `Subtotal (${productsQuantity} itens)`
    } else {
      return `Subtotal (${productsQuantity} item)`
    }
  }

  function calculateCardInstallments() {
    let total = cart.total_value;
    if (cart.discount_amount_checkout && Number(cart.discount_amount_checkout) > 0)
      total += Number(cart.discount_amount_checkout);

    let maxPermited = getInstallmentValue(total);
    const totalOfCardDivided = total / maxPermited;
    return `${maxPermited} x ${formatMoneyMask(totalOfCardDivided.toFixed(2).toString())} s/ juros`
  }

  function renderShippingServiceList() {
    return (
      <>
        {shippingServicesList?.map((item) => (
          <ShippingServicesList
            key={item.price}
            data={item}
            shippingSelected={item.selected}
            onSelectShippingService={() => onSelectShippingService(item)}
          />
        ))}
      </>
    )
  }

  return (
    <div className="cart-resume-container">
      {isLoadingResume && (
        <div className='loading-overlay'>
          <Loading size='BIG2X' />
        </div>
      )}

      <h4>Resumo do pedido</h4>

      <div className="divisor" />

      <div className="subtotal-content">
        <span>{showCartItemsQuantity()}</span>
        <span className="subtotal-value">{formatMoneyMask(cart.sub_total)}</span>
      </div>

      <div className="divisor" />

      {!isOnlyDigitalProduct && (
        <>
          <div className="shipping">
            <span className='shipping-title'>Frete</span>
              <div className='shipping-calculate'>
                <input
                  type='text'
                  placeholder='Digite o CEP'
                  onChange={handlePostalCode}
                  value={postalCode}
                />

                <button
                  type='button'
                  className='btn-calculate'
                  disabled={postalCode?.length !== 9}
                  onClick={onCalculateShipping}
                >
                  <span>Calcular</span>
                </button>
              </div>
          </div>

          {renderShippingServiceList()}

          <div className="divisor" />
        </>
      )}

      {cart.discount_amount && Number(cart.discount_amount) > 0 ? <div className="total-content">
        <span>Desconto</span>
        <div className="total-resume">
          <h6 className="value">- {formatMoneyMask(cart.discount_amount)}</h6>
        </div>
      </div> : null}

      {cart.discount_amount_coupon && Number(cart.discount_amount_coupon) > 0 ? <div className="total-content">
        <span>{cart.coupon ? <>Cupom ({cart.coupon})</> : <>Desconto</>}</span>
        <div className="total-resume">
          <h6 className="value">- {formatMoneyMask(cart.discount_amount_coupon)}</h6>
        </div>
      </div> : null}

      {cart.discount_amount_payment && Number(cart.discount_amount_payment) > 0 ? <div className="total-content">
        <span>Desconto (PIX)</span>
        <div className="total-resume">
          <h6 className="value">- {formatMoneyMask(cart.discount_amount_payment.toString())}</h6>
        </div>
      </div> : null}

      {cart.discount_amount_customer && Number(cart.discount_amount_customer) > 0 ? <div className="total-content">
        <span>Desconto Cliente</span>
        <div className="total-resume">
          <h6 className="value">- {formatMoneyMask(cart.discount_amount_customer.toString())}</h6>
        </div>
      </div> : null}

      {cart.discount_amount_campaign && Number(cart.discount_amount_campaign) > 0 ? <div className="total-content">
        <span>Desconto</span>
        <div className="total-resume">
          <h6 className="value">- {formatMoneyMask(cart.discount_amount_campaign.toString())}</h6>
        </div>
      </div> : null}

      {cart.shipping && cart.shipping.price && cart.shipping_price !== null ? <div className="total-content">
        <span>Frete</span>
        <div className="total-resume">
          <h6 className="value">{formatMoneyMask(cart.shipping_price)}</h6>
        </div>
      </div> : null}

      <div className="total-content">
        <span>Total</span>
        <div className="total-resume">
          {cart.discount_amount_checkout && Number(cart.discount_amount_checkout) > 0 ?
            <h6>{formatMoneyMask((cart.total_value + Number(cart.discount_amount_checkout)).toString())}</h6> : <h6>{formatMoneyMask(cart.total_value.toString())}</h6>}
          <h6 className="installment">{calculateCardInstallments()}</h6>
        </div>
      </div>

      <button data-shipping={cart?.shipping?.name} className='btn-continue btn-cart' type='button' onClick={() => navigate('/completar', {state: `cart${isOnlyDigitalProduct ? "Digital": ""}`})}>
        <span>Continuar</span>
        <img src={arrowIcon} alt="arrow" />
      </button>
    </div>
  )
}
