import React from 'react'
import styles from "./styles.module.scss";

import pix from "../../../../assets/icons/pix-completo.svg";
import clock from "../../../../assets/icons/clock.svg";
import emoji from "../../../../assets/icons/emoji-smile.svg";
import security from "../../../../assets/icons/shield-check.svg";

export default function Pix() {

  return (
    <>
      <div className={styles.pix}>
        <div className={styles.group}>
          <p>Pague com</p>
          <img src={pix} alt={"pix"}/>
        </div>
        <div className={styles.info}>
          <div className={styles.align}>
            <img src={clock} alt={"clock"}/>
            <p>Acesso liberado na hora</p>
            <span>Pagando com pix, o acesso ao seu material será liberado na hora</span>
            <span>Obs.: Para pré-venda, a liberação se mantém na data informada.</span>
          </div>
          <div className={styles.align}>
            <img src={emoji} alt={"emoji"}/>
            <p>Simples</p>
            <span>Basta abrir o aplicativo do seu banco, procurar por PIX e escanear o QRcode.</span>
          </div>
          <div className={styles.alignSecurity}>
            <img src={security} alt={"security"}/>
            <p>100% Seguro</p>
            <span>O pagamento com PIX foi desenvolvido pelo Banco Central para facilitar seus pagamentos.</span>
          </div>
        </div>
        <div className={styles.alignMobile}>
          <img src={security} alt={"security"}/>
          <p>100% Seguro</p>
          <span>O pagamento com PIX foi desenvolvido pelo Banco Central para facilitar seus pagamentos.</span>
        </div>
      </div>
    </>
  )
}
