export const formatPostalCode = (value: string) => {
  if (!value) {
    return;
  }

  return value
    .replace(/\D/g, '')
    .replace(/^(\d{5})(\d{3})+?$/, '$1-$2');
}

export function formatPhone(phoneNumber: string) {
  return phoneNumber
    .replace(/\D/g, '')
    .replace(/^(\d{2})\B/, '($1) ')
    .replace(/(\d{1})?(\d{4})(\d{4})/, '$1$2-$3');
}

export const formatMoneyMask = (value: string) => {
  if (!value) {
    return 'R$ 0,00';
  }

  const options = {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL'
  }

  const result = new Intl.NumberFormat('pt-BR', options).format(
    parseFloat(value)
  );

  return result;
}

export const formatMask = (value: string) => {
  if (!value) {
    return '0,00';
  }

  const options = {
    minimumFractionDigits: 2,
    // style: 'currency',
    // currency: 'BRL'
  }

  const result = new Intl.NumberFormat('pt-BR', options).format(
    parseFloat(value)
  );

  return result;
}

export const formatFloatToDecimal = (value: string) => {
  const splitedDiscount = value.split('.');

  return splitedDiscount[0];
}

export const toBoolean = (value: string|undefined, defaultValue: boolean) => {
  if(typeof value === 'undefined') {
    return defaultValue;
  }
  return value === "true";
}
