import React from 'react';
import { useNavigate } from 'react-router-dom';

import arrowLeftIcon from '../../assets/icons/arrow-left.svg';
import './styles.scss';

interface ContentProps {
  children: React.ReactNode;
  path: string;
  className?: string;
}

export default function Content({ children, path, className }: ContentProps) {
  const navigate = useNavigate();

  function handleBackToPage(path: string) {
    navigate(path);
  }

  return (
    <div className={`content ${className ? className : ''}`}>
      <span className='back-page' onClick={() => handleBackToPage(path)}>
        <img src={arrowLeftIcon} alt="arrow-icon"/>
        Voltar
      </span>
      {children}
    </div>
  )
}
