import React, { useEffect, useRef, useState } from 'react';

import styles from "../styles.module.scss";

import arrowRight from "../../../assets/icons/arrowRight.svg";

import pix from "../../../assets/icons/pix.svg";
import pixCompleto from "../../../assets/icons/pix-completo.svg";
import clock from "../../../assets/icons/clock.svg";
import emoji from "../../../assets/icons/emoji-smile.svg";
import security from "../../../assets/icons/shield-check.svg";

interface PagarmePixProps {
  option: string;
  selectPaymentMethod: Function
}

export default function PagarmePix({ option, selectPaymentMethod }: PagarmePixProps) {

  function renderPixOption() {
    return (
      <div className={styles.result}>
        <div className={styles.information}>
          <div className={styles.informationPix}>
            <span>Pague com</span>
            <img src={pixCompleto} alt={"pix"}/>
          </div>
          <div className={styles.info}>
            <div className={styles.align}>
              <img src={clock} alt={"clock"}/>
              <p>Acesso liberado na hora</p>
              <span>Pagando com pix, o acesso ao seu material será liberado na hora.</span>
              <span>Obs.: Para pré-venda, a liberação se mantém na data informada.</span>
            </div>
            <div className={styles.align}>
              <img src={emoji} alt={"emoji"}/>
              <p>Simples</p>
              <span>Basta abrir o aplicativo do seu banco, procurar por PIX e escanear o QRcode.</span>
            </div>
            <div className={`${styles.align}`}>
              <img src={security} alt={"security"}/>
              <p>100% Seguro</p>
              <span>O pagamento com PIX foi desenvolvido pelo Banco Central para facilitar seus pagamentos.</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return <>
    <div onClick={() => selectPaymentMethod('pagarme_pix')}
         className={option === 'pagarme_pix' ? styles.option + " " + styles.active : styles.option}
    >
      <div className={styles.group}>
        <img className={styles.pix} src={pix} alt="pix"/>
        <p>
          PIX<span>Aprovação imediata</span>
        </p>
      </div>
      <div>
        <img src={arrowRight} alt="arrow"/>
      </div>
    </div>
    {option === 'pagarme_pix' && (
      renderPixOption()
    )}
  </>
}
