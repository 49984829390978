import React, {useEffect, useRef, useState} from 'react';
import {Product} from "../../ts/interfaces/Product";
import styles from './styles.module.scss';
import thunder from "../../assets/icons/thunder.svg";
import {formatMask} from "../../helper/FormatFields";

interface DiscountLightningProps {
  products: Product[];
  reload: Function;
  className: string;
  totalValue: number;
  discount: number;
}

export default function DiscountLightning({products, reload, className, totalValue, discount}: DiscountLightningProps) {

  const [timer, setTimer] = useState<string | null>('');
  const Ref = useRef(null);

  useEffect(() => {
    let item = products.find((item) => item.expires_at && (new Date(item.expires_at)).getTime() > (new Date()).getTime())
    if (item && item.expires_at) {
      clearTimer((new Date(item.expires_at)).toString());
    }
  }, [products]);

  const getTimeRemaining = (e: string) => {
    const total = Date.parse(e) - Date.parse((new Date()).toString());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 * 60 * 60) % 24);
    return {
      total, hours, minutes, seconds
    };
  }

  const startTimer = (e: string) => {
    let {total, hours, minutes, seconds} = getTimeRemaining(e);
    if (total >= 0) {
      setTimer((minutes > 9 ? minutes : '0' + minutes) + 'm ' + (seconds > 9 ? seconds : '0' + seconds) + 's')
    } else {
      setTimer('');
      reload()
      if (Ref.current) clearInterval(Ref.current);
    }
  }

  const clearTimer = (e: string) => {

    if (Ref.current) clearInterval(Ref.current);
    startTimer(e);

    const id = setInterval(() => {
      startTimer(e);
    }, 1000)
    // @ts-ignore
    Ref.current = id;
  }

  return (timer != '' ? <>

      {className === 'cart' ? <div className={styles.discountLightning}>
        <span>Desconto Relâmpago Ativado - Expira em: {timer}</span>
      </div> : ''}

      {className === 'payment' ? <div className={styles.showLightningDiscount}>
        <div className={styles.divImageThunder}>
          <img src={thunder} alt={"thunder"}/>
          <label>DESCONTO RELÂMPAGO ATIVADO</label>
        </div>
        <div className={styles.divPriceDiscount}>
          <p>Você vai pagar <span>R$</span> {formatMask(totalValue.toString())}, se comprar nos
            próximos {timer}
          </p>
        </div>
        <p className={styles.differenceDiscount}>Uma economia de {Math.round(((((totalValue + discount) - ((totalValue + discount) - discount)) / ((totalValue + discount))) * 100))}% sobre os produtos</p>
      </div> : ''}
    </> : <></>
  )
}
