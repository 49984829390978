export default function getInstallmentValue(totalCart: number) {
  let maxPermited = Math.floor(Number(totalCart) / Number(process.env.REACT_APP_MIN_INSTALLMENT_VALUE))

  if(maxPermited < 1) {
    maxPermited = 1;
  }

  if(maxPermited > Number(process.env.REACT_APP_MAX_INSTALLMENTS)) {
    maxPermited = Number(process.env.REACT_APP_MAX_INSTALLMENTS);
  }
  return maxPermited;
}
