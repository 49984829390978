import {createContext, ReactNode, useEffect, useState} from 'react';
import { Cart } from '../ts/interfaces/Cart';

interface ContextProps {
  children: ReactNode;
}

interface CartContextProps {
  isOnlyDigitalProduct: boolean;
  cart: Cart;
  onChangeCart: (cart: Cart) => void;
}

export const CartContext = createContext({} as CartContextProps);

export default function Context({ children }: ContextProps) {
  const [isOnlyDigitalProduct, setOnlyDigitalProduct] = useState(false);
  const [cart, setCart] = useState({} as Cart);

  useEffect(() => {
    if(cart) {
      const isOnlyDigitalProducts = cart.items?.every((item) => !item.is_shipping);
      setOnlyDigitalProduct(isOnlyDigitalProducts)
    }
  }, [cart]);

  return (
    <CartContext.Provider
      value={{
        cart,
        isOnlyDigitalProduct,
        onChangeCart: setCart
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
