import React from 'react';

import styles from "../styles.module.scss";
import arrowRight from "../../../assets/icons/arrowRight.svg";
import upc from "../../../assets/icons/upc.svg";

interface MercadoPagoBoletoProps {
  option: string;
  selectPaymentMethod: Function
}

export default function MercadoPagoBoleto({option, selectPaymentMethod}: MercadoPagoBoletoProps) {

  function renderBoleto() {
    return (
      <div className={styles.result}>
        <p className={styles.information}>
          Na próxima etapa você terá acesso ao link para impressão do boleto
        </p>
      </div>
    )
  }

  return <>
    <div
      onClick={() => selectPaymentMethod('mercadopago_boleto')}
      className={option === 'mercadopago_boleto' ? styles.option + " " + styles.active : styles.option}
    >
      <div className={styles.group}>
        <img src={upc} alt="upc"/>
        <p>
         Boleto<span>Aprovado em até 2 dias após o pagamento.</span>
        </p>
      </div>
      <div>
        <img src={arrowRight} alt="arrow"/>
      </div>
    </div>
    {option === 'mercadopago_boleto' && (
      renderBoleto()
    )}
  </>
}
