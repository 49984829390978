import React from 'react'
import styles from "./styles.module.scss";


export default function Ticket() {

  return (
    <>
      <div className={styles.form}>
        <div className={styles.align}>
          <div className={styles.information}>
            <p>CEP</p>
            <div className={styles.group}>
              <input
                id="name"
                type="text"
              />
              <span className={styles.cep}>Não sei o CEP</span>
            </div>
          </div>
          <div className={styles.group}>
            <div className={styles.information}>
              <p>Endereço</p>
              <input
                className={styles.address}
                id="cpf"
                maxLength={14}
              />
            </div>
            <div className={styles.information}>
              <p className={styles.numberText}>Número</p>
              <input
                className={styles.number}
                id="email"
                type="text"
              />
            </div>
          </div>
          <div className={styles.group}>
            <div className={styles.mobileAddress}>
              <div className={styles.information}>
                <p className={styles.numberMobileText}>Número</p>
                <input
                  className={styles.numberMobile}
                  id="email"
                  type="text"
                />
              </div>
              <div className={styles.information}>
                <p>Complemento</p>
                <input
                  className={styles.completeMobile}
                  id="email"
                  type="text"
                />
              </div>
            </div>
            <div className={styles.information}>
              <p className={styles.completeText}>Complemento</p>
              <input
                className={styles.complete}
                id="email"
                type="text"
              />
            </div>
            <div className={styles.information}>
              <p>Bairro</p>
              <div className={styles.info}>
                <input
                  className={styles.burgh}
                  id="cpf"
                  maxLength={14}
                />
                <span>Alfenas-MG</span>
              </div>
            </div>
          </div>
          <div className={styles.information}>
            <p>Referência</p>
            <input
              className={styles.reference}
              id="cpf"
              maxLength={14}
            />
          </div>
        </div>
      </div>
    </>
  )
}
