import React, {useEffect} from 'react';
import styles from "./styles.module.scss";
import Header from "../../component/Header/header";
import {useLocation, useNavigate} from "react-router-dom";
import {useMutation} from "react-query";
import {recoverCart} from "../../services/services";
import handleErrorMessage from "../../helper/handleErrorMessage";
import {toast} from "react-toastify";
import Preloader from "../../component/Preloader";

import useSessionStorage from "../../hooks/useSessionStorage";

const CartRecover = () => {

  const [showPixParcelado, setShowPixParcelado] = useSessionStorage(
    '@pix-parcelado', ''
  );

  const {search} = useLocation();
  const navigate = useNavigate();

  const {mutate, isLoading} = useMutation((data: { session_id: string }) =>
    recoverCart(data), {
    onSuccess: ({data}) => {
      if (data.success) {
        let params = document.location.search.split("&");
        params.shift();
        let paramsUrl = params.join("&");
        document.location.href = "/" + (paramsUrl.length > 0 ? "?" + paramsUrl : "");
        setShowPixParcelado("true");
      } else {
        toast.error("Erro inesperado, tente mais tarde");
      }
    },
    onError: (error: { message: string, response: { status: number, data: { errors: [] } } }) => {
      handleErrorMessage(error.response);
    }
  });

  useEffect(() => {
      const sessionId = new URLSearchParams(search).get('session_id');
      if(sessionId) {
        mutate({session_id: sessionId})
      }
  }, [search]);

  return (<>
      <Preloader isVisible={isLoading}/>
      <div className={styles.separateDivs}>
        <Header></Header>
        <div className={styles.content}>
        </div>
      </div>
    </>
  )
}

export default CartRecover
