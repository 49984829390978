import React, {useState, useEffect} from 'react';
import styles from "./styles.module.scss";
import Header from "../../component/Header/header";
import Footer from "../../component/Footer/footer";
import eye from "../../../src/assets/icons/eye.svg";
import {changePassword} from "../../services/services";
import {useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import handleErrorMessage from "../../helper/handleErrorMessage";
import {useMutation} from "react-query";
import {
  GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3';

const Remember = () => {

  document.title = 'Alterar Senha';

  const {search} = useLocation();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [payload, setPayload] = useState({code: "", token: ""});

  useEffect(() => {
    const code = new URLSearchParams(search).get('code');
    const token = new URLSearchParams(search).get('token');
    setPayload({code: code ? code : "", token: token ? token : ""})
  }, [search]);

  const {mutate, isLoading} = useMutation(async (data: {password: string, password_confirmation: string, code: string, token: string}) => {
    return await changePassword(data).catch((error) => { handleErrorMessage(error.response); return error; });
  }, {
    onSuccess: ({response, data}) => {
      if (data && data.msg) {
        if(data.success) {

          toast.info(data.msg, {autoClose: false});

          setPassword("");
          setPasswordConfirmation("");
          setTimeout(() => {
            navigate("/entrar");
          }, 300)
        } else {
          toast.error(data.msg);
        }
      } else {
        handleErrorMessage({status: response.status, data: {errors: response.data.errors}});
      }
    },
    onError: (error: { message: string, response: { status: number, data: { errors: [] } } }) => {
      handleErrorMessage(error.response);
    }
  });

  async function change(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    mutate({password: password, password_confirmation: passwordConfirmation, code: payload.code, token: payload.token})
  }
  return (
    <div className={styles.separateDivs}>
      <Header/>
      <div className={styles.content}>
        <div className={styles.login}>
          <h1>Alterar senha</h1>
        </div>
        <div className={styles.boxLogin}>
          <form onSubmit={(event) => change(event)}>
            <div className={styles.align}>
              <p>Informe uma nova senha</p>
              <div className={styles.password}>
                <input id="senha" type={showPassword ? "text" : "password"} placeholder="Informe uma senha segura" value={password} onChange={(event) => setPassword(event.target.value)}/>
                <img src={eye} alt="eye" onClick={() => setShowPassword(!showPassword)}/>
              </div>
              <p>Confirmar senha</p>
              <div className={styles.password}>
                <input id="senha" type={showConfirmPassword ? "text" : "password"}
                       placeholder="Repita a senha digitada anteriormente" value={passwordConfirmation} onChange={(event) => setPasswordConfirmation(event.target.value)}/>
                <img src={eye} alt="eye" onClick={() => setShowConfirmPassword(!showConfirmPassword)}/>
              </div>
            </div>
            <div className={styles.formGroup}>
              <button type="submit" disabled={isLoading}>Alterar Senha</button>
            </div>
          </form>
        </div>
        <div className={styles.outsideFormGroup}>
          <a href={"/register"} type="button">Preciso de ajuda</a>
        </div>
      </div>
    </div>
  )
}

const TheRemember = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY ? process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY : ""}>
      <Remember/>
    </GoogleReCaptchaProvider>
  )
}

export default TheRemember
