import { Outlet } from 'react-router-dom';

import Header from '../../component/Header/header';

import './styles.scss';

export default function Main() {
  return (
    <>
      <Header />
      <div className='main-container'>
        <Outlet />
      </div>
    </>
  );
}
