import { useEffect, useState, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Preloader from '../../component/Preloader';
import Content from '../../component/Content';
import Button from '../../component/Button';
import AddressComponent from '../../component/Address';

import { Address } from '../../ts/interfaces/Address';
import { AddressContext } from '../../context/AddressContext';
import { getMyAddresses, selectAddress, deleteAddress } from '../../services/services';

import './styles.scss';

export default function MyAddresses() {

  document.title = 'Meus endereços';

  const navigate = useNavigate();
  const { setAddress: onChangeAddress } = useContext(AddressContext);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [addressSelected, setAddressSelected] = useState(0);
  const [isLoading, setLoading] = useState(false);

  async function handleSelectAddress(id: number) {
    setAddressSelected(id);
    setLoading(true);

    try {
      await selectAddress(id);
    } catch (error: any) {
      console.error('HANDLESELECTADDRESS::ERROR', error);
    } finally {
      setLoading(false);
    }
  }

  function handleEditAddress(address: Address) {
    onChangeAddress(address);
    navigate('/enderecos/editar');
  }

  const selectAddressAfterRemove = useCallback(async () => {
    const id = addresses[0].id!
    setAddressSelected(id);
    await selectAddress(id);
  }, [addresses]);

  const handleDeleteAddress = useCallback(async (addressId: number) => {
    setLoading(true);
    try {
      const { data } = await deleteAddress(addressId);

      if (data.success) {
        setAddresses(
          (prevState) => prevState.filter(
            (address) => address.id !== addressId
          )
        );

        if (addressId === addressSelected) {
          selectAddressAfterRemove();
        }
      }
    } catch (error) {
      console.error('DELETEADDRESS::ERROR', error);
    } finally {
      setLoading(false);
    }
  }, [addressSelected, selectAddressAfterRemove]);

  function renderAddresses() {
    return addresses.map((item) => (
      <AddressComponent
        key={item.id}
        className='address'
        data={item}
        canEdit
        addressIdSelected={addressSelected}
        onSelectAddress={(id) => handleSelectAddress(id)}
        onEditAddress={() => handleEditAddress(item)}
        onDeleteAddress={handleDeleteAddress}
        canDelete={addresses?.length > 1}
      />
    ))
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await getMyAddresses();

        if (data.success) {
          setAddresses(data.data.addresses);

          setAddressSelected(data.data.address_id_selected
            ? data.data.address_id_selected
            : data.data.addresses[0].id
          );
        }
      } catch (error: any) {
        console.error('GETMYADDRESSES::ERROR', error);
      } finally {
        setLoading(false);
      }
    })()
  }, []);

  useEffect(() => {
    (async () => {
      if (addresses.length === 1) {
        setLoading(true);

        try {
          selectAddressAfterRemove();
        } catch (error) {
          console.error('USEEFFECT_ADDRESSSELECT::ERROR', error);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [addresses, selectAddressAfterRemove]);

  return (
    <div className="my-addresses-container">
      <Preloader isVisible={isLoading} />

      <h1>Meus endereços</h1>

      <Content path='/frete'>
        {addresses.length > 0 ? (renderAddresses()) : null}

        <Button
          className='btn'
          title='Continuar'
          onClick={() => navigate('/frete')}
        />
      </Content>
      <Button
        className='btn-register'
        type='SECUNDARY'
        title='Cadastrar endereço'
        onClick={() => navigate('/enderecos/adicionar')}
      />
    </div>
  )
}
