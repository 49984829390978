import React, {useEffect, useRef, useState} from 'react';

import styles from "../styles.module.scss";

import arrowRight from "../../../assets/icons/arrowRight.svg";
import pagalevepix from "../../../assets/icons/payment-pagaleve.png";
import pagalevelogo from "../../../assets/icons/payment-pagaleve-logo.svg";

interface PagalevePixParceladoProps {
  option: string;
  selectPaymentMethod: Function
}

export default function PagalevePixParcelado({option, selectPaymentMethod}: PagalevePixParceladoProps) {

  function renderPixParceladoOption() {
    return (
      <div className={styles.result}>
        <p className={`${styles.information} ${styles.noMargin}`}>
          <img src={pagalevepix} alt={"paga leve pix"}/>
        </p>
      </div>
    )
  }

  return <>
    <div onClick={() => selectPaymentMethod('pagaleve_pix_parcelado')}
         className={option === 'pagaleve_pix_parcelado' ? styles.option + " " + styles.active : styles.option}
    >
      <div className={styles.group}>
        <img className={styles.pixparcelado} src={pagalevelogo} alt="pix"/>
        <p>
          PIX 4x sem juros<span>Aprovação imediata</span>
        </p>
      </div>
      <div>
        <img src={arrowRight} alt="arrow"/>
      </div>
    </div>
    {option === 'pagaleve_pix_parcelado' && (
      renderPixParceladoOption()
    )}
  </>
}
