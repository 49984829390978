import React from 'react'

import {ToastContainer} from 'react-toastify';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {CookiesProvider} from 'react-cookie';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import AppRouter from './routes/Routes';

const colors = {
  nova: {
    colorPrimary: '#198b54',
    colorSecondaryBackground: '#DEFFEE'
  },
  solucao: {
    colorPrimary: '#0079b8',
    colorSecondaryBackground: '#c5e1f6'
  },
  opcao: {
    colorPrimary: '#395187',
    colorSecondaryBackground: '#c4daf8'
  },
  maxi: {
    colorPrimary: '#0f4b87',
    colorSecondaryBackground: '#ffffff'
  }
}

function App() {

  React.useEffect(() => {
    let whiteLabelLogo = process.env.REACT_APP_WHITE_LABEL ?? 'nova';

    const root = document.documentElement;
    // @ts-ignore
    root?.style.setProperty("--color-primary", colors[whiteLabelLogo].colorPrimary);
    // @ts-ignore
    root?.style.setProperty("--color-secondary-background", colors[whiteLabelLogo].colorSecondaryBackground);

    let faviconUrl = process.env.REACT_APP_FAVICON_URL ?? '';

    let link = document.querySelector("link[rel~='icon']");
    if(link) {
      // @ts-ignore
      link.href = faviconUrl;
    }

  }, []);

  return (
    <>
      <CookiesProvider>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID
            ? process.env.REACT_APP_GOOGLE_CLIENT_ID
            : '281808405308-0n0vi5uqhlhpe5pp692ta8ae1iq264ls.apps.googleusercontent.com'}
        >
          <AppRouter/>
        </GoogleOAuthProvider>
      </CookiesProvider>
      <ToastContainer/>
    </>
  );
}

export default App;
