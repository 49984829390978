import { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { formatPostalCode, formatPhone } from '../../helper/FormatFields';
import { Address } from '../../ts/interfaces/Address';
import { registerAddress } from '../../services/services';
import { AddressContext } from '../../context/AddressContext';

import Content from '../../component/Content';
import Preloader from '../../component/Preloader';
import Input from '../../component/Input';
import InputZipCode from '../../component/Input/InputZipCode';
import Button from '../../component/Button';

import './styles.scss';

export default function MyAddressesAddEdit() {

  const params = useParams();
  const navigate = useNavigate();
  const { address } = useContext(AddressContext);

  document.title =  params.type === 'adicionar' ?  'Adicionar endereço' : 'Editar endereço';

  const [isLoading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name_receiver: Yup.string().required('Preencha com um nome'),
    phone_receiver: Yup.string()
      .required('Preencha com um número de telefone'),
    zipcode: Yup.string()
      .required('Preencha o cep'),
    address: Yup.string()
      .required('Preencha o endereço'),
    number: Yup.string()
      .required('Preencha o número'),
    district: Yup.string()
      .required('Preencha o bairro'),
  });

  const {
    register,
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<Address>({
    resolver: yupResolver(validationSchema),
  });

  function handlePhone(e: React.ChangeEvent<HTMLInputElement>) {
    const formatedValue = formatPhone(e.target.value);
    setValue('phone_receiver', formatedValue);
  }

  function handleCEP(e: React.ChangeEvent<HTMLInputElement>) {
    const cep = formatPostalCode(e.target.value);
    setValue('zipcode', formatPostalCode(cep!)!);
  }

  async function handleSearchZipCode() {
    const zipCode = getValues('zipcode').replace('-', '');

    if (!zipCode) {
      setError('zipcode', { message: 'Preencha um CEP' });
      return;
    }

    if (zipCode.length !== 8) {
      setError('zipcode', { message: 'Preencha um CEP valido' });
      return;
    }

    setLoading(true);

    fetch(`https://viacep.com.br/ws/${zipCode}/json/`)
      .then((res) => res.json()).then((data) => {
        if (data.erro) {
          setError('zipcode', { message: 'CEP não encontrado' });
          return;
        }

        clearErrors('zipcode');
        setValue('state', data.uf);
        setValue('city', data.localidade);
        setValue('district', data.bairro);
        setValue('address', data.logradouro);
      }).finally(() => {
        setLoading(false);
      });
  }

  async function onSubmit(address: Address) {
    setLoading(true);

    try {
      const { data }: any = await registerAddress(address);

      if (data.success) {
        navigate('/enderecos');
      }
    } catch (error) {
      console.error('SAVE_ADDRESS::ERROR', error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (params.type === 'editar') {
      //@ts-ignore
      delete address.customer;
      reset({ ...address });
    }
  }, [address, reset, params.type]);

  useEffect(() => {
    if (Object.keys(address).length === 0 && params.type !== 'adicionar') {
      navigate('/enderecos');
    }
  }, [address, params, navigate]);

  const pageTitle = params.type === 'adicionar'
    ? 'Adicionar endereço'
    : 'Editar endereço'

  return (
    <div className='my-addresses-container'>
      <Preloader isVisible={isLoading} />

      <h1>{pageTitle}</h1>

      <Content path='/enderecos'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label='Nome'
            fieldName='name_receiver'
            register={register}
            errorMessage={errors.name_receiver?.message}
          />

          <Input
            label='Telefone'
            fieldName='phone_receiver'
            placeholder="(XX) - 9XXXX-XXXX"
            maxLength={15}
            register={register}
            onChangeField={handlePhone}
            errorMessage={errors.phone_receiver?.message}
          />

          <div className='zipcode-row'>
            <InputZipCode
              label='Informe o CEP'
              fieldName='zipcode'
              placeholder='00000-000'
              register={register}
              onChangeField={handleCEP}
              buttonLabel='Pesquisar'
              errorMessage={errors.zipcode?.message}
              onClick={handleSearchZipCode}
            />

            <a
              className='search-cep'
              target="_blank"
              rel="noreferrer"
              href="https://buscacepinter.correios.com.br/app/endereco/index.php/"
            >
              Não sei o cep
            </a>
          </div>

          <Input
            fieldName='address'
            label='Endereço'
            errorMessage={errors.address?.message}
            register={register}
          />

          <div className='inputs-row'>
            <Input
              fieldName='number'
              label='Número'
              errorMessage={errors.number?.message}
              register={register}
            />

            <Input
              fieldName='complement'
              label='Complemento'
              register={register}
            />
          </div>

          <Input
            fieldName='district'
            label='Bairro'
            errorMessage={errors.district?.message}
            register={register}
          />

          <Input
            fieldName='reference'
            label='Referência'
            register={register}
          />

          {!!getValues('city') && !!getValues('state') && (
            <p className='address-state'>{getValues('city')}/{getValues('state')}</p>
          )}

          <Button
            type='PRIMARY'
            title='Salvar'
            buttonType='submit'
          />
        </form>
      </Content>
    </div>
  )
}
