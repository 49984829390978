import {useCallback, useEffect, useState, useContext} from 'react';
import {useNavigate} from 'react-router-dom';

import AddressShipping from '../../component/Address';
import Preloader from '../../component/Preloader';
import Content from '../../component/Content';
import Button from '../../component/Button';

import {ShippingService} from '../../ts/interfaces/Shipping';
import {Address} from '../../ts/interfaces/Address';

import {
  calculateShipping, selectShippingService, getAddressToSendProduct, getCart
} from '../../services/services';

import ShippingList from './ShippingList';
import './styles.scss';
import {CartContext} from '../../context/CartContext';

export default function Shipping() {

  document.title = 'Escolha uma opção de envio';

  const navigate = useNavigate();
  const {isOnlyDigitalProduct, onChangeCart, cart} = useContext(CartContext);

  const [shippingData, setShippingData] = useState([] as ShippingService[]);
  const [address, setAddress] = useState({} as Address);
  const [loading, setLoading] = useState(true);
  const [isLoadingSelect, setLoadingSelect] = useState(false);
  const [isLoadingShipping, setLoadingShipping] = useState(true);
  const [isLoadingAddress, setLoadingAddress] = useState(true);
  const [zipcode, setZipCode] = useState('');

  const handleSelectShipping = useCallback(async (shipping: ShippingService) => {
    setLoadingSelect(true);

    delete shipping?.id;
    try {
      let {data} = await selectShippingService({
        ...shipping,
        address_id: address?.id,
        cep: address?.zipcode.replace('-', '')
      });

      setShippingData(data.data.services);
    } catch (error) {
      console.error('SELECTSHIPPING::ERROR', error);
    } finally {
      setLoadingSelect(false);
    }
  }, [address?.id, address?.zipcode]);

  const getAddress = useCallback(async () => {

    setLoadingAddress(true)

    const {data} = await getAddressToSendProduct();
    const result = data.data;

    if (result) {
      setAddress(result);
      setZipCode(result.zipcode);
    }
    setLoadingAddress(false)

  }, []);

  const getShippingServiceList = useCallback(async () => {
    if (!zipcode) return;

    setLoadingShipping(true);
    const {data} = await calculateShipping(zipcode.replace('-', ''));
    if (data && data.data && data.data.services) {
      setShippingData(data.data.services);
    }
    setLoadingShipping(false);

  }, [zipcode]);

  const loadCart = useCallback(async () => {
    try {
      setLoading(true);
      const {data} = await getCart();

      if (data.success) {
        await onChangeCart(data.data);
        if(data.data === null) {
          navigate('/');
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isOnlyDigitalProduct) {
      navigate('/pagamento');
    }
  }, [isOnlyDigitalProduct, navigate]);

  useEffect(() => {
    getShippingServiceList()
  }, [getShippingServiceList]);

  useEffect(() => {
    getAddress();
  }, [getAddress]);

  useEffect(() => {
    loadCart();
  }, [loadCart]);

  return (
    <div className="shipping-container">
      <Preloader isVisible={loading || isLoadingShipping || isLoadingSelect || isLoadingAddress}/>
      {loading ? "" : <>
        <h1>Escolha uma opção de envio</h1>
        <Content path='/completar'>
          <div className={"shipping-content"}>
            <span className='send-to-text'>Enviar para</span>
            {Object.values(address).length > 0 && (
              <AddressShipping
                data={address}
                canEdit={false}
                onChooseAddress={() => navigate('/enderecos')}
              />
            )}
            <div className='shipping-list'>
              {shippingData.map((item) => (
                <ShippingList
                  key={item.name}
                  shippingSelected={item.selected}
                  shipping={item}
                  onSelectShipping={handleSelectShipping}
                />
              ))}
            </div>
            <Button className={"btn-shipping"} dataShipping={shippingData.find((item) => item.selected)?.name} title='Ir para pagamento' onClick={() => navigate('/pagamento')}/>
          </div>
        </Content>
      </>}
    </div>
  )
}
