import { QueryClientProvider } from "react-query";
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from "@sentry/react";

import './index.scss';
import App from "./App";
import reportWebVitals from './reportWebVitals';
import {queryClient} from "./services/queryClient";

TagManager.initialize({
  gtmId: process.env.REACT_APP_TAGMANAGER ? process.env.REACT_APP_TAGMANAGER : ""
})

Sentry.init({
  dsn: "https://54df11d094962a1a6b734095ce8eabb1@o4508251248001024.ingest.us.sentry.io/4508290614231040",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/checkout\.novaconcursos\.com\.br/, /^https:\/\/checkout\.editorasolucao\.com\.br/, /^https:\/\/checkout\.apostilasopcao\.com\.br/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
