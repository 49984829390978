const EnumPaymentMethodParameter = {
  xpc: 'pagarme_cc',
  xmpc: 'mercadopago_cc',
  xpb: 'pagarme_boleto',
  xmpb: 'mercadopago_boleto',
  xpp: 'pagarme_pix',
  xmpp: 'mercadopago_pix',
  xsb: 'show_boleto',
  xspp: 'show_pix_parcelado',
  xcp: 'custompayment'
}

export default EnumPaymentMethodParameter
