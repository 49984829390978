import ReactInputMask from 'react-input-mask';
import './styles.scss';

interface MaskedInputProps {
  placeholder?: string;
  mask: string;
  errorMessage?: string;
  className?: string;
  fieldName: string;
  register: any;
}

const MaskedInput = ({
  placeholder,
  mask,
  register,
  fieldName,
  className,
  errorMessage,
}: MaskedInputProps) => {
  return (
    <div className='form-control'>
      <ReactInputMask
        className={className && className}
        placeholder={placeholder}
        mask={mask}
        {...register(fieldName)}
      />
      {errorMessage && (
        <span className='error'>{errorMessage}</span>
      )}
    </div>
  )
};

export default MaskedInput
