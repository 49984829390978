import React, {useState} from 'react';
import styles from "./styles.module.scss";
import HeaderComponent from "../Header";

import pointer from "../../../assets/icons/pointerArrow.svg";
import arrow from "../../../assets/icons/arrow.svg";
import lock from "../../../assets/icons/iconLock.svg";
import {Customer} from "../../../ts/interfaces/Customer";
import {saveCustomer} from "../../../services/services";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {cpf} from "cpf-cnpj-validator";

function formatPhone(phoneNumber: string) {
  return phoneNumber
    .replace(/\D/g, '')
    .replace(/^(\d{2})\B/, '($1) ')
    .replace(/(\d{1})?(\d{4})(\d{4})/, '$1$2-$3');
}

function formatCPF(cpf: string) {
  return cpf
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

function formatCEP(cep: string) {
  return cep
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d{3})/, '$1-$2');
}

export default function PaymentData() {

  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Preencha com seu nome para continuar'),
    email: Yup.string()
      .required('Preencha com seu email para continuar')
      .email('Email inválido'),
    email_confirmation: Yup.string()
      .required('Confirme seu email para continuar')
      .email('Email inválido')
      .oneOf([Yup.ref('email'), null], "Email não coincide"),
    cpf: Yup.string()
      .required('Preencha seu CPF')
      .min(14, 'Preencha um CPF válido')
      //@ts-ignore
      .test('is-valid-cpf', (item, schema) => {
        if (!item) {
          return schema.createError({
            message: 'Preencha seu CPF',
            path: 'cpf',
          });
        }

        if (!cpf.isValid(item)) {
          return schema.createError({
            message: 'CPF preenchido é inválido',
            path: 'cpf',
          });
        }

        return true;
      }),

    phone: Yup.string()
      .required('Preencha seu celular'),
    zipcode: Yup.string()
      .required('Preencha seu cep'),
    address: Yup.string()
      .required('Preencha com seu endereço'),
    number: Yup.string()
      .required('Preencha seu número'),
    district: Yup.string()
      .required('Preencha seu bairro'),
  });

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<Customer>({
    resolver: yupResolver(validationSchema),
  });

  function handlePhone(e: React.ChangeEvent<HTMLInputElement>) {
    const formatedValue = formatPhone(e.target.value);
    setValue('phone', formatedValue);
  }

  function handleCPF(e: React.ChangeEvent<HTMLInputElement>) {
    const formatedValue = formatCPF(e.target.value);
    setValue('cpf', formatedValue);
  }

  async function onSubmit(data: Customer) {
    setLoading(true);

    try {
      if (!data) {
        return;
      }

      const customer = {
        cpf: data.cpf,
        phone: data.phone,
        zipcode: data.zipcode,
        address: data.address,
        number: data.number,
        district: data.district,
        city: data.city,
        state: data.state,
        complement: data.complement,
        reference: data.reference,
      };

      const response = await saveCustomer(customer);
      if (response.data.success) {
        navigate('/data');
      }
    } catch (error) {
      console.error('ERROR::ON_SUBMIT', error);
    } finally {
      setLoading(false);
    }
  }


  return (
    <>
      <HeaderComponent/>
      <div className='main-container'>
        <div className={styles.box}>
          <div className={styles.data}>
            <div className={styles.header}>
              <div className={styles.firstPage}>
                <p className={styles.number}>1</p>
                <span>Dados pessoais</span>
              </div>
              <img className={styles.partition} src={pointer} alt={"pointer"}/>
              <div className={styles.secondPage}>
                <p className={styles.number}>2</p>
                <span>Pagamento</span>
              </div>
            </div>
            <div className={styles.body}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.boxData}>
                  <div className={styles.align}>
                    <div className={styles.information}>
                      <p>Nome Completo</p>
                      <input
                        id="name"
                        type="text"
                        placeholder="Informe o seu nome completo"
                        {...register('name')}
                      />
                      <span className={styles.error}>{errors.name?.message}</span>
                    </div>
                    <div className={styles.information}>
                      <p>Informe seu CPF</p>
                      <input
                        id="cpf"
                        placeholder="000.000.000-00"
                        maxLength={14}
                        {...register('cpf', {
                          onChange: handleCPF,
                        })}
                      />
                      <span className={styles.error}>{errors.cpf?.message}</span>
                    </div>
                    <div className={styles.information}>
                      <p>E-mail</p>
                      <input
                        id="email"
                        type="text"
                        placeholder="Informe o seu e-mail"
                        {...register('email')}
                      />
                      <span className={styles.error}>{errors.email?.message}</span>
                    </div>
                    <div className={styles.information}>
                      <p>Confirme seu E-mail</p>
                      <input
                        id="email_confirmation"
                        type="text"
                        placeholder="Confirme o seu e-mail"
                        {...register('email_confirmation')}
                      />
                      <span className={styles.error}>{errors.email_confirmation?.message}</span>
                    </div>
                    <div className={styles.information}>
                      <p>Informe seu Celular</p>
                      <input
                        id="phone"
                        placeholder="(XX) - 9XXXX-XXXX"
                        maxLength={15}
                        {...register('phone', {
                          onChange: handlePhone,
                        })}
                      />
                      <span className={styles.error}>{errors.phone?.message}</span>
                    </div>
                  </div>

                  <div className={styles.formGroup}>
                    <p>Ao continuar, você concorda com os <a href={""}>termos de uso</a> e <a href={""}>políticas de
                      privacidade</a>.</p>
                    <button type="submit">
                      Continuar
                      <img src={arrow} alt="arrow"/>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className={styles.summary}>
            <div className={styles.title}>
              <p>Resumo da compra</p>
              <div className={styles.safety}>
                <p><img src={lock} alt={"lock"}/>COMPRA 100% SEGURA</p>
              </div>
            </div>
            <table>
              <tr>
                <td className={styles.info}>Apostila Polícia Penal MG 2021 - Agente de Segurança Penitenciário (PP-MG)
                </td>
                <td>10</td>
                <td>R$ 75,00</td>
              </tr>
              <tr>
                <td className={styles.info}>Apostila PC-MG 2021 Escrivão</td>
                <td>1</td>
                <td>R$ 99,00</td>
              </tr>
            </table>
            <div className={styles.discount}>
              <hr/>
              <div className={styles.agroupment}>
                <p className={styles.strong}>Desconto</p>
                <p>- R$ 15,00</p>
              </div>
            </div>
            <div className={styles.totalPrice}>
              <div className={styles.alignPrice}>
                <p>Total</p>
                <p>12 x R$ 10,00</p>
              </div>
              <div className={styles.inCash}>
                <span>Total à vista</span>
                <span>R$ 120,00</span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
