import React, {useEffect, useRef, useState} from 'react';

import styles from "../styles.module.scss";

import arrowRight from "../../../assets/icons/arrowRight.svg";
import gift from "../../../assets/icons/gift.svg";

interface CustomPaymentProps {
  option: string;
  selectPaymentMethod: Function
}

export default function CustomPayment({option, selectPaymentMethod}: CustomPaymentProps) {

  function renderFreeOption() {
    return (
      <div className={styles.result}>
        <p className={styles.information}>
          Clique em finalizar compra para efetuar o pedido, obs: o pedido já será confirmado
        </p>
      </div>
    )
  }

  return <>
    <div
      onClick={() => selectPaymentMethod('custompayment')}
      className={option === 'custompayment' ? styles.option + " " + styles.active : styles.option}
    >
      <div className={styles.group}>
        <img src={gift} alt="upc"/>
        <p>
          Informações de Pagamento Desnecessárias
        </p>
      </div>
      <div>
        <img src={arrowRight} alt="arrow"/>
      </div>
    </div>
    {option === 'custompayment' && (
      renderFreeOption()
    )}
  </>
}
