import React, {useState} from 'react';
import styles from "./styles.module.scss";
import {Modal} from "reactstrap";

import close from "../../assets/icons/close.svg";
import {rememberPassword} from "../../services/services";
import {toast} from "react-toastify";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

interface ModalProps {
  modalOpen: boolean;
  closeModal: () => void;
}

const ModalRemeberPassword = ({modalOpen, closeModal}: ModalProps) => {

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  async function remember(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if(!email) {
      toast.error('E-mail obrigatório');
      return false;
    }

    let token = null;
    if(executeRecaptcha) {
      token = await executeRecaptcha('rememberPassword');
    }
    if(token) {
      setLoading(true);
      await rememberPassword({email: email, token: token});
      toast.success(`Se existir uma conta associada com ${email} você receberá um email com um link para redefinir sua senha.`, {autoClose: false})
      setLoading(false);
      setEmail("");
    } else {
      toast.error('Verifique o captcha');
    }
  }

  return (
    <>
      <Modal
        isOpen={modalOpen}
        toggle={closeModal}
        className={styles.modal}>
        <div className={styles.recover}>
          <div className={styles.content}>
            <div className={styles.boxLogin}>
              <div className={styles.close}>
                <img src={close} onClick={closeModal} alt={"close"}/>
              </div>
              <h4>Lembrar senha</h4>
              <span>Informe o e-mail de cadastro e clique em enviar. Nós iremos enviar um e-mail com dados para recuperar sua senha.</span>
              <form onSubmit={(event) => remember(event)}>
                <p>E-mail</p>
                <input type="text" placeholder="Informe o seu e-mail" value={email} onChange={(event) => setEmail(event.target.value)}/>
                <div className={styles.formGroup}>
                  <button type="submit" className="btn-continuar-pedido" disabled={loading}>Enviar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalRemeberPassword
