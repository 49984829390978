declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export default function hasEvent(event: string) {
  window.dataLayer =  window.dataLayer || [];
  return window.dataLayer.some((item: any) => item.event === event)
}
