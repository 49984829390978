import ReactDOM from 'react-dom';

import Button from '../Button';
import './styles.scss';

interface ModalProps {
  type: 'PRIMARY' | 'SECUNDARY' | 'DANGER';
  buttonTitle: string;
  modalTitle: string;
  modalDescription: string;
  onClick: () => void;
  onCloseModal: () => void;
  isVisible: boolean;
}

export default function Modal({
  isVisible,
  buttonTitle,
  modalTitle,
  modalDescription,
  onClick,
  onCloseModal,
  type = 'PRIMARY',
}: ModalProps) {
  if (!isVisible) return null;

  return ReactDOM.createPortal(
    <div className='overlay'>
      <div className='container'>
        <h1>{modalTitle}</h1>
        <p>
          {modalDescription}
        </p>

        <div className='footer'>
          <Button type={type} title={buttonTitle} onClick={onClick} />

          <button type="button" className="cancelButton" onClick={onCloseModal}>
            Cancelar
          </button>
        </div>
      </div>
    </div>,
    document.getElementById('modal-root') as HTMLElement,
  );
}
