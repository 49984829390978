import arrowIcon from '../../assets/icons/arrowRight.svg';
import './styles.scss';

interface ButtonProps {
  className?: string;
  title: string;
  type?: 'PRIMARY' | 'SECUNDARY' | 'DANGER';
  buttonType?: any;
  onClick?: () => void;
  disabled?: boolean;
  dataShipping?: string
}

export default function Button({
  onClick, title, className, type = 'PRIMARY', buttonType = 'button', disabled = false, dataShipping
}: ButtonProps) {

  function getButtonColor() {
    switch (type) {
      case 'SECUNDARY':
        return 'btn-secundary';
      case 'DANGER':
        return 'btn-danger';
      default:
        return 'btn-primary';
    }
  }

  return (
    <button
      data-shipping={dataShipping}
      disabled={disabled}
      className={`btn ${getButtonColor()} ${className ? className : ""}`}
      type={buttonType}
      onClick={onClick}
    >
      <span>{title}</span>
      {type === 'PRIMARY' && (
        <img src={arrowIcon} alt="arrow" />
      )}
    </button>
  )
}
