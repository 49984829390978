import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import PaymentResume from '../PaymentResume';
import paymentCheck from '../../../assets/icons/payment-check.svg';
import './styles.scss';
import {getOrderByCart} from "../../../services/services";
import {Order} from "../../../ts/interfaces/Order";
import Preloader from "../../../component/Preloader";

export default function PixPaymentFinish() {

  document.title = 'Pix aprovado!';

  const navigate = useNavigate();

  const [order, setOrder] = useState<Order>();
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setLoading(true);
    getOrderByCart().then(result => {
      setOrder(result.data.order);
      setLoading(false);
    })
  }, [navigate]);

  return (
    <>
      <Preloader isVisible={loading}/>

      {order ?
        <div className='payment-finish-container'>
          <div className='payment-finish-content'>
            <img src={paymentCheck} alt="payment check"/>
            <h2 className='description'>Parabéns! Pix aprovado com sucesso.</h2>
            <div className='numeroPedido'>
              <span>O número do seu pedido é:</span>
              <p className='orderNumber'>{order?.increment_id} </p>
            </div>
          </div>
          <div className="payment-method">
            <a target="_blank" rel="nofollow"
               href={process.env.REACT_APP_AREA_CLIENTE_URL + "/?from=checkout&redirect=#/login"}
               className="acessar-area-aluno">Acessar Área do Aluno</a>
          </div>
          <PaymentResume
            order={order}
          />
        </div> :
        <p></p>
      }
    </>
  )
}
