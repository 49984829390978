import React from 'react';
import styles from "./styles.module.scss";
import {Modal} from "reactstrap";


interface ModalProps {
  modalOpen: boolean;
  closeModal: () => void;
  urlIframe: string
}

const Modalpagaleve = ({modalOpen, closeModal, urlIframe}: ModalProps) => {

  return (
    <>
      <Modal
        isOpen={modalOpen}
        toggle={closeModal}
        className={styles.modal}>
        <div className={styles.recover}>
          <div className={styles.content}>
            <div className={styles.box}>
              <iframe className={styles.iframe} src={urlIframe}/>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Modalpagaleve
