import React, {useContext, useRef, useState, useEffect} from 'react';
import {useMutation} from "react-query";

import {selectOrderBump} from "../../../services/services";
import {formatMoneyMask} from "../../../helper/FormatFields";
import {toast} from "react-toastify";

import {Cart} from "../../../ts/interfaces/Cart";

import handleErrorMessage from "../../../helper/handleErrorMessage";

import {CartContext} from "../../../context/CartContext";

import styles from "./styles.module.scss";
import Preloader from "../../../component/Preloader";
import {array} from "yup";

export default function OrderBump({cart, setCart, checkoutDiscount}: {
  cart: Cart,
  setCart: Function,
  checkoutDiscount: boolean
}) {
  const Ref = useRef(null);

  const [show, setShow] = useState(sessionStorage.getItem("showOrderBump") !== "false");
  const [timer, setTimer] = useState<string | null>('00:00:00');
  const {onChangeCart} = useContext(CartContext);

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + (5 * 60));
    return deadline.toString();
  }

  const getTimeRemaining = (e: string) => {
    const total = Date.parse(e) - Date.parse((new Date()).toString());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 * 60 * 60) % 24);
    return {
      total, hours, minutes, seconds
    };
  }

  const startTimer = (e: string) => {
    let {total, hours, minutes, seconds}
      = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : '0' + hours) + ':' +
        (minutes > 9 ? minutes : '0' + minutes) + ':'
        + (seconds > 9 ? seconds : '0' + seconds)
      )
    } else {
      //sessionStorage.setItem("showOrderBump", "false");
      setShow(false);
    }
  }

  const clearTimer = (e: string) => {
    setTimer('00:05:00');
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000)
    // @ts-ignore
    Ref.current = id;
  }

  function calcDesc(price: number, specialPrice: number) {
    return (100 - ((specialPrice * 100) / price)).toFixed(0);
  }

  function hasInCart(sku: string) {
    return cart.items?.some((item) => item.sku === sku)
  }

  const {mutate: mutateBump, isLoading: isLoadingBump} = useMutation(async (data: { ids: number[] }) => {
    return await selectOrderBump(data).catch((error) => {
      handleErrorMessage(error.response);
      return error;
    });
  }, {
    onSuccess: ({response, data}) => {
      if (data.success && data.data && data.data.cart) {
        onChangeCart(data.data.cart)
        setCart(data.data.cart);
        toast.success(data.data.msg)
      } else {
        toast.error(data.data.msg)
      }
    },
    onError: (response) => {
      toast.error("Erro inesperado, tente mais tarde");
    }
  });

  const handleSelectAll = () => {
    let ids = cart.order_bumps?.map((item) => {
      return item.id;
    });

    mutateBump({ids: ids});
  }

  const getSelectAll = () => {
    let confirm = true;
    let cartSkus = cart.items?.map((item) => {
      return item.sku;
    })
    let bumpSkus = cart.order_bumps?.map((item) => {
      return item.bump_sku;
    })

    bumpSkus?.map((bump) => {
      if (!cartSkus?.includes(bump)) {
        confirm = false;
      }
    })

    return confirm;
  }

  return (<>
    <Preloader isVisible={isLoadingBump}/>
    {cart && cart.order_bumps && cart.order_bumps.length > 0 && show ? <div className={styles.orderBump}>
      <p className={styles.pOferta}>Oferta Especial</p>
      {cart.order_bumps.map((item) => {
        return <div key={item.id} className={`${styles.oferta} ${isLoadingBump ? styles.disabled : ""}`}
                    onClick={() => mutateBump({ids: [item.id]})}>
          <div className={`${styles.checkbox} ${hasInCart(item.bump_sku) ? styles.selected : styles.notSelected}`}>
            <span>✔</span>
          </div>
          <div className={styles.bumpInfo}>
            <span className={styles.bumpName}>{item.bump_name}</span>
            {item.bump_special_price ?
              <span className={styles.bumpPrice}>por {formatMoneyMask(item.bump_special_price.toString())}</span> : ""}
            {item.bump_description && (item.bump_name != item.bump_description) ?
              <span className={styles.bumpDescription}
                    dangerouslySetInnerHTML={{__html: item.bump_description ?? ""}}/> : ''}
          </div>
        </div>
      })}
      {!checkoutDiscount ?
        <div className={styles.expireIn}>
          <p>Expira em {timer}</p>
        </div> : ""
      }
      {cart && cart.order_bumps && cart.order_bumps.length > 1 && show &&
        <div className={styles.selecionarTodos} onClick={() => handleSelectAll()}>
          <div className={`${styles.checkbox} ${styles.all} ${getSelectAll() ? styles.selected : styles.notSelected}`}>
            <span>✔</span>
          </div>
          <div>
            <span className={styles.selecionar}>Selecionar todos ofertas</span>
          </div>
        </div>
      }
    </div> : ""}
  </>)
}
