import { useState } from 'react';
import './styles.scss';

import { formatPostalCode } from '../../helper/FormatFields';
import { Address } from '../../ts/interfaces/Address';
import locationIcon from '../../assets/icons/location.svg';
import trashIcon from '../../assets/icons/trash.svg';

import Modal from '../../component/Modal';


interface AddressProps {
  data: Address;
  canDelete?: boolean;
  canEdit?: boolean;
  className?: string;
  addressIdSelected?: number;
  onSelectAddress?: (id: number) => void;
  onChooseAddress?: () => void;
  onEditAddress?: () => void;
  onDeleteAddress?: (addressId: number) => void;
}

function getAddressSelectedStyles(addressSelected: boolean) {
  if (addressSelected) {
    return {
      checkbox: 'checkbox-selected'
    }
  } else {
    return {
      checkbox: 'checkbox-default'
    }
  }
}

export default function AddressComponent({
  data,
  addressIdSelected,
  className,
  onSelectAddress,
  onChooseAddress,
  onEditAddress,
  onDeleteAddress,
  canDelete,
  canEdit = false,
}: AddressProps) {
  const [modalIsVisible, setModalVisible] = useState(false);
  const selectedStyles = getAddressSelectedStyles(addressIdSelected === data.id);

  function handleDeleteAddress() {
    setModalVisible(false);

    if (onDeleteAddress) {
      onDeleteAddress(data.id!);
    }
  }

  return (
    <div className={`address-container ${className && className}`}>
      <div className={`address-content ${canEdit ? 'align-checkbox' : 'align-icon'}`}>
        {canEdit ? (
          <div
            className={`checkbox ${selectedStyles.checkbox}`}
            onClick={() => onSelectAddress && onSelectAddress((data.id!))}
          >
            <div />
          </div>
        ) : (
          <img src={locationIcon} alt="location-icon" />
        )}

        <div className='description'>
          <p>{data.address}, {data.number}</p>
          <p>{data.city}, {data.state} <span className='text-break'>CEP: {formatPostalCode(data.zipcode)}</span></p>
          <div className='receiver-information'>
            <span className='receiver-name'>{data.name_receiver}</span>
            <span className='receiver-phone'>{data.phone_receiver}</span>
          </div>
        </div>
      </div>

      <div className='address-footer'>
        {canEdit ? (
          <>
            <span onClick={onEditAddress}>Editar endereço</span>
            {canDelete ? (
              <img
                src={trashIcon}
                onClick={() => setModalVisible(true)}
              />
            ) : null}
          </>
        ) : (
          <span
            className='address-choose'
            onClick={onChooseAddress}
          >
            Editar ou escolher outro
          </span>
        )}
      </div>

      <Modal
        type='DANGER'
        isVisible={modalIsVisible}
        buttonTitle='Excluir'
        modalTitle='Excluir endereço'
        modalDescription='Deseja excluir o endereço?'
        onClick={handleDeleteAddress}
        onCloseModal={() => setModalVisible(false)}
      />
    </div>
  )
}
