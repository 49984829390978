import {toast} from "react-toastify";

export default function handleErrorMessage(response: { status: number, data: { errors: any } } ) {
    console.log(response);
    if(response.status === 500) {
        toast.error("Ocorreu um erro inesperado, nossos analistas foram notificados, tente mais tarde.");
    } else {
        let keys = Object.keys(response.data.errors);
        let values = null;
        if (keys.length > 0) {
            values = keys.map((key) => {
                return response.data.errors[key].join(',')
            }).join(',')
        }
        toast.error(values ? values : "Ocorreu um erro inesperado, nossos analistas foram notificados, tente mais tarde.", {autoClose: false})
    }
}
