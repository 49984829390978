/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Address } from '../ts/interfaces/Address';
import useLocalStorage from '../hooks/useLocalStorage';

interface ContextProps {
  address: Address;
  setAddress: (address: Address) => void;
}

export const AddressContext = createContext({} as ContextProps);

export default function Context({ children }: { children: ReactNode }) {
  const location = useLocation();
  const [addressStoraged, setAddressStoraged] = useLocalStorage('@address', {} as any);

  useEffect(() => {
    if (Object.values(addressStoraged).length !== 0) {
      setAddressStoraged(addressStoraged);
    }
  }, [addressStoraged]);

  useEffect(() => {
    if (location.pathname !== '/enderecos/editar') {
      setAddressStoraged({});
    }
  }, [location]);

  return (
    <AddressContext.Provider value={{ address: addressStoraged, setAddress: setAddressStoraged }}>
      {children}
    </AddressContext.Provider>
  )
}
