import {Route, Routes as Router, useLocation} from 'react-router-dom';
import {CookiesProvider} from 'react-cookie';
import {useEffect} from "react";
import TagManager from 'react-gtm-module';

import Payment from "../views/Payment";
import PaymentFinish from "../views/Payment/PaymentFinish";
import PixPaymentFinish from "../views/Payment/PixPaymentFinish";
import Cart from "../views/Cart/Cart";
import Login from "../views/Login/Login";
import Home from "../views/Home/Home";
import Register from "../views/Register/Register";
import Remember from "../views/Remember/Remember";
import Complete from "../views/Complete/Complete";
import Shipping from "../views/Shipping";
import {MyAddresses, MyAddressesAddEdit} from '../views/MyAddresses';

import AddressProvider from '../context/AddressContext';
import Main from '../containers/Main';
import RequireAuth from './RequireAuth';
import CartProvider from '../context/CartContext';
import PaymentData from "../views/Payment2/PaymentData";
import PaymentPay from "../views/Payment2/PaymentPay";
import SignatureAccount from "../views/SignatureAccount/SignatureAccount";
import CartRecover from "../views/CartRecover/CartRecover";


export default function AppRouter() {

  const location = useLocation();

  function parseType(pathName: string) {
    switch (pathName) {
      case "/":
        return "carrinho";
      case "/pagamento":
        return "pagamento";
      case "/pagamento/finalizado":
        return "sucesso";
      default:
        break;
    }
    return pathName.replaceAll("/", "");
  }

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        pageName: document.title,
        pageType: parseType(location.pathname),
      }
    })
  }, [location]);


  return (
    <CookiesProvider>
      <CartProvider>
        <AddressProvider>
          <Router>
            <Route path={'/continuar'} element={<Home/>}/>
            <Route path={'/entrar'} element={<Login/>}/>
            <Route path={'/cadastro'} element={<Register/>}/>
            <Route path={'/alterar-senha'} element={<Remember/>}/>
            <Route path={'/convite-assinatura/:hash'} element={<SignatureAccount/>}/>
            <Route path={'/carrinho-abandonado'} element={<CartRecover/>}/>
            <Route path={'/'} element={<Cart/>}/>

            <Route path={'/checkout2/completar'} element={<PaymentData/>}/>
            <Route path={'/checkout2/pagamento'} element={<PaymentPay/>}/>
            <Route
              element={(
                <RequireAuth>
                  <Main/>
                </RequireAuth>
              )}>
              <Route path={'/completar'} element={<Complete/>}/>
              <Route path={'/frete'} element={<Shipping/>}/>
              <Route path={'/enderecos'} element={<MyAddresses/>}/>
              <Route path={'/enderecos/:type'} element={<MyAddressesAddEdit/>}/>
              <Route path={'/pagamento'} element={<Payment/>}/>
              <Route path={'/pagamento/finalizado'} element={<PaymentFinish/>}/>
              <Route path={'/pagamento-pix/finalizado'} element={<PixPaymentFinish/>}/>
            </Route>
          </Router>
        </AddressProvider>
      </CartProvider>
    </CookiesProvider>
  );
}
