import {useContext, useEffect, useState} from "react";
import {useMutation} from "react-query";
import {applyCouponCart, resetCheckoutDiscount} from "../../services/services";
import handleErrorMessage from "../../helper/handleErrorMessage";
import {toast} from "react-toastify";
import {Cart} from "../../ts/interfaces/Cart";
import {CartContext} from "../../context/CartContext";

interface TimerPropos {
  maxRange: number,
  setExpiredCheckoutDiscount: Function,
  setCart: Function;
}

function Timer({maxRange, setExpiredCheckoutDiscount, setCart}: TimerPropos) {
  const [counter, setCounter] = useState(maxRange);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const { onChangeCart} = useContext(CartContext);

  const {mutate: mutateResetCheckoutDiscount, isLoading: isLoadingResetCheckoutDiscount} = useMutation(async (data: {}) => {
    return await resetCheckoutDiscount().catch((error) => {
      handleErrorMessage(error.response);
      return error;
    });
  }, {
    onSuccess: ({response, data}) => {
      if (data) {
        if (data.success && data.data) {
          onChangeCart(data.data)
          setCart(data.data);
        } else {
          toast.error("Erro inesperado, tente mais tarde.")
        }
      } else {
        if (response) {
          handleErrorMessage({status: response.status, data: {errors: response.data.errors}});
        } else {
          toast.error("Erro inesperado, tente mais tarde.")
        }
      }
    },
    onError: (response) => {
      console.log(response)
      toast.error("Erro inesperado, tente mais tarde");
    }
  });

  async function resetCheckoutDiscountMutate() {
    mutateResetCheckoutDiscount({});
  }

  useEffect(() => {
    if (counter > 0) {
      setMinutes(Math.floor((counter / 60) % 60));
      setSeconds(Math.floor((counter) % 60));
      setTimeout(() => setCounter(counter - 1), 1000);
    }
    else
    {
      setMinutes(0);
      setSeconds(0);
      setExpiredCheckoutDiscount(true);
      resetCheckoutDiscountMutate();
    }

  }, [counter]);

  return (
    <label>
      {(minutes > 9 ? minutes : '0' + minutes) + ':'
      + (seconds > 9 ? seconds : '0' + seconds)}
    </label>
  );
}

export default Timer;
