import React from 'react'
import styles from "./styles.module.scss";

import LogoIcon from "./LogoIcon";
import lock from "../../assets/icons/lock.svg";

const Header = () => {
    return (
        <div className={styles.header}>
            <div className={styles.align}>
                <a className={styles.logo} href={process.env.REACT_APP_URL_HOME}><LogoIcon color={""} width={""} name={"logo"}/></a>
                <span><img src={lock} alt={"lock"}/> Site<strong> 100% seguro</strong></span>
            </div>
        </div>
    )
}

export default Header
