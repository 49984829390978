import React from 'react'
import styles from "./styles.module.scss";

import logo from "../../../assets/icons/colorido.svg";

export default function HeaderComponent() {

  return (
    <>
      <div className={styles.header}>
        <div className={styles.align}>
          <a href="https://www.novaconcursos.com.br/"><img src={logo} alt={"logo"}/></a>
        </div>
      </div>
    </>
  )
}
