import React from 'react';
import styles from "./styles.module.scss";
import Header from "../../component/Header/header";
import Footer from "../../component/Footer/footer";
import {Link} from "react-router-dom";

const Home = () => {

    document.title = 'Para continuar, acesse sua conta';

    return (
        <div className={styles.separateDivs}>
            <Header></Header>
            <div className={styles.content}>
                <div className={styles.box}>
                    <h3>
                        Para continuar, acesse sua conta
                    </h3>
                    <form action="">
                        <div className={styles.formGroup}>
                            <Link to={"/cadastro"} type="button" className={styles.btnNew}>Sou novo</Link>
                            <Link to={"/entrar"} type="button" className={styles.btnOld}>Já tenho conta</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Home
